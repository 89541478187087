
import { Component, Prop } from 'vue-property-decorator';
import BaseFormBuilder from '../base-form-builder/base-form-builder.vue';
import { ValidationBuilder } from '../../utils/validation-builder';
import { FormioBuilderOptionsSchema, FormioBuilderGroupSchema } from '../../formio-interfaces/FormioBuilderOptionsSchema';
import { OnlinePortalWidgetBuilderOptionsSchema } from '../../formio-interfaces/OnlinePortalWidgetBuilderOptionsSchema';
import { FormioFormSchema } from '../../formio-interfaces/FormioFormSchema';
import { CustomBuilderGroups } from '../../enums/custom-builder-groups';
import { widgetComponentsSchema } from './product-selection-builder.helper';
import { ApplicationTemplate } from '@sageworks/jpi';

@Component
export default class ProductSelectionBuilder extends BaseFormBuilder {
	@Prop()
	public subscribedApplicationTypes!: ApplicationTemplate.TypeEnum[];

	get builder(): FormioBuilderOptionsSchema {
		if (this.templateDataFieldMapping == null) {
			throw new Error('Template datafield mapping cannot be null');
		}

		const builder: OnlinePortalWidgetBuilderOptionsSchema = {
			[CustomBuilderGroups.productSelectionWidgets]: {
				title: 'Widgets',
				weight: 2,
				default: true,
				components: widgetComponentsSchema(this.subscribedApplicationTypes, this.templateDataFieldMapping)
			} as FormioBuilderGroupSchema
		};

		return builder;
	}

	protected validateForm(): string[] {
		const form: FormioFormSchema = this.form;

		return new ValidationBuilder(form.components)
			.validateForMinUsages(1, { fieldType: 'LoanAmountInput' }, 'Product selection must include a Loan Amount widget.')
			.validateForMaxUsages(1, { fieldType: 'LoanAmountInput' }, 'Loan Amount widget already exists within this form and cannot be added twice.')
			.buildValidations();
	}
}
