
import Vue, { PropType } from 'vue';
import { ApplicationSpinner, ApplicationWizardCard } from '../../../components/application-wizard';
import SignatureSection from './SignatureSection.vue';
import {
	AuthenticatedForm,
	DynamicFormsContextData,
	LoanRoleType,
	ToastrUtils,
	FormPathUtils,
	TemplateDataField
} from '@sageworks/dynamic-forms';
import { UrlUtils, ApplicationNavigationUtils, ApplicationFormPdfCssStyleUtils, NavMetadataUtils, HooksBuilder } from '../../../utils';
import { IAppConfiguration } from '../../../models';
import { OnlinePortalRoutes } from '../../../OnlinePortalRoutes';
import { ComponentState, DATA_PROCESSING_COMPONENT_STATES } from '../../../enums';
import { ApplicationTemplateMetadata, LoanApplication, UsableProducts } from '@sageworks/jpi';
import moment from 'moment';
import { getProposedProductName } from '../../../utils/proposed-products/proposed-product-helper';
import { mapGetters, mapState } from 'vuex';
import { MultiLoanApplicationFormModuleState } from '../../../store/MultiLoanApplicationFormModule/state';
import { LoanApplicationMetadataModuleState } from '../../../store/LoanApplicationMetadataModule';
import Component from 'formiojs/components/_classes/component/Component';

export default Vue.extend({
	name: 'MultiLoanApplicationForm',
	components: {
		ApplicationSpinner,
		ApplicationWizardCard,
		AuthenticatedForm,
		SignatureSection
	},
	props: {
		applicationId: { type: String as PropType<string | null>, default: null },
		forceReadOnly: { type: Boolean, default: false },
		lenderOnlyMode: { type: Boolean, default: true },
		forceExportState: { type: Boolean, default: false },
		messageChannel: { type: String, default: '' }
	},
	data() {
		return {
			componentState: ComponentState.INIT,
			navigationTriggered: false as Boolean,
			navigationMetadata: [],
			enableExport: false as Boolean,
			currentPageIndex: 0 as Number,
			hooks: new HooksBuilder()
				.addCrudHooks(this, this.directStore)
				.addFetchDataHook(this.directStore)
				.addUpdateDataHook(this.directStore)
				.addCreateComponentHook(this)
				.build(),
			ComponentState,
			LoanRoleType
		};
	},
	computed: {
		...mapState('MultiLoanApplicationForm', ['loanIndex', 'renderData']),
		...mapGetters('MultiLoanApplicationForm', ['currentSubmissionData', 'currentMetadata']),
		...mapState('LoanApplicationData', ['loanApplication']),
		...mapGetters('LoanApplicationData', ['loanOfficerFullName']),
		...mapGetters('StylingSettings', ['logoUrl']),
		...mapState('LoanApplicationMetadata', ['metadata']),
		...mapState('Authentication', ['apiToken']),
		applicationIdAsNumber(): number | null {
			if (this.applicationId == null) {
				return null;
			}

			const id = Number.parseInt(this.applicationId, 10);
			if (Number.isNaN(id)) {
				return null;
			}

			return id;
		},
		formioConfigList(): any[] {
			const { metadata } = this.directStore.state.LoanApplicationMetadata as LoanApplicationMetadataModuleState;
			const currentMetadata = this.currentMetadata as ApplicationTemplateMetadata;
			const { submissionDataLookup } = this.directStore.state.MultiLoanApplicationForm as MultiLoanApplicationFormModuleState;
			const loanIndex = this.loanIndex;
			let configList: any[] = [];

			if (!this.forceReadOnly) {
				configList = [
					{
						data: submissionDataLookup[loanIndex],
						formPath: FormPathUtils.createFormPath(currentMetadata?.applicationTemplate)
					}
				];
			} else {
				configList = metadata.map(({ applicationTemplate }, index) => ({
					data: submissionDataLookup[index],
					formPath: FormPathUtils.createFormPath(applicationTemplate)
				}));
			}

			return configList.filter(x => x.formPath);
		},
		formioDataObjectsToRender(): any[] {
			if (this.forceReadOnly) {
				return this.directStore.getters.MultiLoanApplicationForm.allSubmissionData;
			}
			return [this.currentSubmissionData];
		},
		applicationSubmittedDate(): string | null {
			if (
				this.loanApplication == null ||
				!this.loanApplication.submittedDate ||
				!moment(this.loanApplication.submittedDate, 'YYYY-MM-DD', true).isValid()
			)
				return null;
			return moment(this.loanApplication.submittedDate, 'YYYY-MM-DD').format('MMMM Do, YYYY');
		},
		showFormSpinner(): boolean {
			const loanMappingId = this.currentMetadata?.loanMappingId;
			const isFormLoading = this.directStore.state.LoanApplicationData.loanDataStatusLookup[loanMappingId]?.isLoading;
			const isDataLoading = DATA_PROCESSING_COMPONENT_STATES.includes(this.componentState);

			return this.componentState === ComponentState.INIT || isFormLoading || isDataLoading;
		},
		showFormError(): boolean {
			return [ComponentState.ERROR, ComponentState.DATA_SAVING_ERROR].includes(this.componentState);
		},
		formioUrl(): string {
			if (this.directStore.state.AppConfig.appConfig == null) {
				return '';
			}

			return (this.directStore.state.AppConfig.appConfig as IAppConfiguration).formioUrl;
		},
		subdomain(): string {
			return UrlUtils.getOnlinePortalSubdomain();
		},
		contextData(): DynamicFormsContextData {
			return {
				applicationId: this.applicationIdAsNumber,
				applicationType: this.loanApplication?.type,
				productName: getProposedProductName(this.currentMetadata?.productProduct),
				isInLenderOnlyMode: this.lenderOnlyMode,
				userInfo: {
					userId: this.directStore.state.User.user?.id ?? null,
					proxyUserId: this.directStore.state.User.proxyUser?.id ?? null
				},
				templateDataFieldMappings: this.directStore.state.CustomComponentProperties.templateDataFieldIdToDataFieldId ?? {},
				propertiesByType: this.directStore.state.CustomComponentProperties.propertiesByType ?? {},
			};
		},
		showForm() {
			return this.formioConfigList.length > 0;
		},
		spinnerLabel(): string {
			switch (this.componentState) {
				case ComponentState.DATA_SAVING:
					return 'Saving your progress';
				case ComponentState.EXPORTING:
					return 'Exporting application to PDF';
				default:
					return `Preparing ${this.itemLabel}`;
			}
		},
		readOnly(): boolean {
			return this.forceReadOnly || this.loanApplication?.applicationStatus === LoanApplication.ApplicationStatusEnum.Locked;
		},
		loanOfficerLoanRepresentativeCode(): string | null {
			const loanOfficerLoanRepresentativeCode = this.directStore.state.LoanApplicationData.loanOfficer?.loanRepresentativeCode;
			if (loanOfficerLoanRepresentativeCode?.length > 0) {
				return loanOfficerLoanRepresentativeCode;
			}
			return null;
		},
		showExportButton(): boolean {
			return this.showForm && this.readOnly && !this.showFormSpinner && !!this.enableExport;
		},
		itemLabel(): string {
			if (this.hasCommunityLendingSubscription === undefined) {
				return '';
			}
			return this.hasCommunityLendingSubscription ? 'Request Form' : 'Application';
		},
		requestOrAppLabel(): string {
			if (this.hasCommunityLendingSubscription === undefined) {
				return '';
			}
			return this.hasCommunityLendingSubscription ? 'Request Form' : 'Loan Application';
		},
		hasCommunityLendingSubscription(): boolean | undefined {
			return this.directStore.state.UsableProducts.usableProducts?.has('abrigoCommunityLending' as UsableProducts.ProductsEnum);
		}
	},
	watch: {
		showExportButton(newShowExportButton: boolean): void {
			// We only need to message the parent window if a message channel is set in the query params
			if (newShowExportButton && this.messageChannel) {
				this.$root.$emit('parent-window-message', { payload: { isLoaded: true }, channel: this.messageChannel });
			}
		}
	},
	created() {
		this.$root.$on('navigate-to-page', this.navigateToPage);
		this.$root.$on('change-loan', this.loanChange);
	},
	async mounted(): Promise<void> {
		this.loadInitialData();
	},
	async destroyed(): Promise<any> {
		this.$root.$off('navigate-to-page', this.navigateToPage);
		this.$root.$off('change-loan', this.loanChange);
		return Promise.all([
			await this.directStore.dispatch.MultiLoanApplicationForm.clearCache(),
			await this.directStore.dispatch.LoanApplicationData.clearAllFormData()
		]);
	},
	methods: {
		nextPage() {
			this.updateCurrentPage();
		},
		previousPage() {
			this.updateCurrentPage();
		},
		updateCurrentPage() {
			const wizardInstance = this.$refs?.form ? this.$refs?.form[0]?.$refs?.$formioForm?.formio : null;

			// ideally this would use reactivity system but unfortunately that was not something I was able to figure out
			return (this.currentPageIndex = wizardInstance?.page ?? 0);
		},
		// eslint-disable-next-line max-lines-per-function
		async loadInitialData() {
			try {
				this.componentState = ComponentState.LOADING;

				if (this.$route?.query?.product > 0) {
					await this.directStore.dispatch.MultiLoanApplicationForm.updateLoanIndex({ loanIndex: this.$route.query.product - 1 });
				}

				await Promise.all([
					this.directStore.dispatch.LoanApplicationData.loadLoanApplicationBundle({ loanApplicationId: this.applicationIdAsNumber }),
					this.directStore.dispatch.LoanApplicationMetadata.loadMetadata({ loanApplicationId: this.applicationId, ignoreFormioProperties: true }),
					this.directStore.dispatch.LoanApplicationMetadata.retrieveBorrowerNameDataFields(),
					this.directStore.dispatch.CustomComponentProperties.loadTemplateDataFieldMappings(),
					this.directStore.dispatch.UsableProducts.fetchUsableProducts()
				]);

				await Promise.all([
					this.directStore.dispatch.LoanApplicationData.fetchApplicationTermsAndCondition({
						applicationTemplateType: this.currentMetadata?.applicationTemplate?.type
					}),
					this.directStore.dispatch.ApplicationTemplates.loadTemplatesByType(this.currentMetadata?.applicationTemplate?.type),
					this.directStore.dispatch.InstitutionSettings.fetchAllowBorrowerToAddRemoveLoans({ applicationType: this.loanApplication?.type }),
					this.directStore.dispatch.LoanApplicationData.loadProposedProductOptions()
				]);

				if (this.forceReadOnly) {
					await this.directStore.dispatch.MultiLoanApplicationForm.loadAllFormData();
				}

				await this.changeCurrentLoan(this.loanIndex);

				this.componentState = ComponentState.LOADED;
			} catch (err) {
				this.componentState = ComponentState.ERROR;
				throw err;
			}
		},
		navigateToDocumentation(form: any, contextData: DynamicFormsContextData) {
			if (!form || !contextData || !this.applicationId || this.navigationTriggered) {
				return;
			}
			if (this.loanIndex === this.metadata.length - 1) {
				ApplicationNavigationUtils.navigateToApplicationDocumentation(this.$router, this.applicationId);
			} else {
				this.moveToNextProduct();
			}
			this.navigationTriggered = true;
		},
		navigateToSubmitApplication(form: any, contextData: DynamicFormsContextData) {
			if (!form || !contextData || !this.applicationId || this.navigationTriggered) {
				return;
			}
			this.$router.push({
				name: OnlinePortalRoutes.ApplicationSubmit.name,
				params: {
					applicationId: this.applicationId
				}
			});
			this.navigationTriggered = true;
		},
		async exportToPdf() {
			this.componentState = ComponentState.EXPORTING;

			// Wait one tick in order to allow the image logo to show in the HTML element
			await this.$nextTick();

			const htmlContent = document.getElementById('exportable-component')?.innerHTML;
			const stylesContent = ApplicationFormPdfCssStyleUtils.retrieveApplicationFormPdfStyles();
			const html = '<html><head>' + stylesContent + '</head><body>' + htmlContent + '</body></html>';

			await this.directStore.dispatch.AdobeESignModule.exportApplicationToPdf({ loanApplicationNumber: this.applicationIdAsNumber, html });
			this.componentState = ComponentState.LOADED;
		},
		saveError() {
			this.error(['An error occured while saving!']);
		},
		error(messages: string[]) {
			this.$root.$bvToast.toast(messages, {
				...ToastrUtils.getFailureOptions()
			});
		},
		formRendered() {
			// This should only run once we have received the emitted message that the form has fully loaded
			this.enableExport = true;
		},
		navigateToPage(payload: { index: number; isValid: boolean; previousPageId?: number }) {
			// @ts-ignore
			const wizardInstance = this.$refs?.form ? this.$refs?.form[0]?.$refs?.$formioForm?.formio : null;

			if (wizardInstance) {
				// Force pristine to false (mimics behavior if you click save)
				wizardInstance.setPristine(false);

				// beforeSubmit is what starts the save hooks
				wizardInstance.currentPanel.beforeSubmit();
				wizardInstance.validateAndSetPage(payload.index, !payload.isValid, payload.previousPageId);
			}

			this.updateCurrentPage();
		},
		async moveToNextProduct() {
			this.loanChange(this.loanIndex + 1);
		},
		async loanChange(index: number) {
			this.componentState = ComponentState.LOADING;
			await this.changeCurrentLoan(index);
			this.componentState = ComponentState.LOADED;
		},
		async changeCurrentLoan(index: number) {
			await this.directStore.dispatch.MultiLoanApplicationForm.loanChange({ loanIndex: index });
			this.navigateToPage({ index: 0, isValid: true });
			this.navigationTriggered = true;

			// nasty but since this gets triggered multiple times on single click need to make
			// sure this doesn't happen twice but also won't prevent future clicks from going to documentation
			setTimeout(() => (this.navigationTriggered = false), 500);
		},
		async formChange({ form }: { form: any; options: any }) {
			if (form) {
				this.navigationMetadata = NavMetadataUtils.createNavMetadataFromFormObject(form);
			}
		},
		async onComponentChange({ component, value }: { component: Component; isValid: boolean; value: any }) {
			const currentMetadata = this.currentMetadata as ApplicationTemplateMetadata;

			// Update the store's flag when the field used to toggle if a loan is 1071 changes
			if (component.templateDataFieldId === TemplateDataField.DidBusinessExceed5Million) {
				await this.directStore.dispatch.MultiLoanApplicationForm.reset1071Flag({ value });
			}

			if (!currentMetadata.loanMappingId || !component.id) return;

			if (this.directStore.getters.LoanApplicationData.componentContainValidationError(currentMetadata.loanMappingId, component.id)) {
				await this.directStore.dispatch.LoanApplicationData.removeComponentValidationError({
					loanMappingId: currentMetadata.loanMappingId,
					componentId: component.id
				});
			}
		},
		async validationErrors(errors: any) {
			await this.directStore.dispatch.LoanApplicationData.addPageValidationError({
				loanMappingId: this.currentMetadata.loanMappingId,
				pageId: errors.pageId,
				errors: errors.errors
			});
		}
	}
});
