import { LoanApplicationCreditAuthorization } from '@sageworks/jpi';

export class AuthorizationDisplayObjectFactory {
	static createAuthorizationDisplayObject(authorizationMetadata: LoanApplicationCreditAuthorization | null = null, username: string | null = '') {
		if (authorizationMetadata && authorizationMetadata?.authorizedDate) {
			return this.createDisplayObject(authorizationMetadata.authorizedDate as any, username);
		}

		return null;
	}

	static createCreditPullDisplayObject(authorizationMetadata: LoanApplicationCreditAuthorization | null = null, username: string | null = '') {
		if (authorizationMetadata && authorizationMetadata?.pullComplete) {
			return this.createDisplayObject(authorizationMetadata.pullDate as any, username);
		}

		return null;
	}

	static createWaivedDisplayObject(authorizationMetadata: LoanApplicationCreditAuthorization | null = null, username: string | null = '') {
		if (authorizationMetadata && authorizationMetadata?.waivedDate) {
			return this.createDisplayObject(authorizationMetadata.waivedDate as any, username);
		}

		return null;
	}

	private static createDisplayObject(date: string, name?: string | null) {
		return {
			name,
			date
		};
	}
}
