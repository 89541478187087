
import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import SidebarDrawer from '@/components/sidebar-drawer/sidebar-drawer.vue';
import { AnonymousBorrowerMinimumAccessService, AutoFactory, Permission } from '@sageworks/jpi';
import { mapGetters } from 'vuex';

export default Vue.extend({
	name: 'BeneficialOwnershipTable1071RowActions',
	components: {
		FontAwesomeIcon,
		SidebarDrawer
	},
	props: {
		customerId: {
			type: Number,
			required: true
		}
	},
	data() {
		return {
			isDrawerOpen: false,
			loadingDemographicFormUrl: false,
			origin: window.location.origin,
			demographicFormUrl: undefined as string | undefined
		};
	},
	computed: {
		...mapGetters('InstitutionSettings', ['allowBorrowerFillOut1071DemographicFormForAllOwners']),
		...mapGetters('MultiLoanApplicationForm', ['proposedLoanId']),
		...mapGetters('User', ['isLender']),
		allowTheUserToEnterDemographicInformation(): boolean {
			return this.directStore.getters.LenderAuthorizationModule.canAccessFeature(Permission.ProductFeatureEnum.AllowTheUserToEnterDemographicInformation);
		},
		showEnterDemographicInfoButton(): boolean {
			if (this.isLender) {
				return this.allowTheUserToEnterDemographicInformation;
			}
			return this.allowBorrowerFillOut1071DemographicFormForAllOwners;
		}
	},
	async mounted() {
		window.addEventListener('message', this.handleFormSubmitted);
		await this.directStore.dispatch.LenderAuthorizationModule.loadUserPermissions();
	},
	destroyed() {
		window.removeEventListener('message', this.handleFormSubmitted);
	},
	methods: {
		hideDrawer() {
			this.isDrawerOpen = false;
		},
		openDrawer() {
			this.isDrawerOpen = true;
		},
		handleFormSubmitted({ origin, data }: MessageEvent<{ canceled: boolean; success: boolean; eventType: string }>) {
			if (!this.demographicFormUrl) {
				return;
			}

			// Ensure the event contains the correct origin so we know it came from the nested iframe
			const expectedOrigin = new URL(this.demographicFormUrl).origin;
			if (origin !== expectedOrigin || data.eventType !== 'SUBMIT_1071_DEMOGRAPHIC_FORM') {
				return;
			}

			this.hideDrawer();
			if (data.success) {
				this.$emit('demographic-form-saved');
			}
		},
		async openDemographicForm() {
			// if the url is already fetched then simplly open the drawer
			if (this.demographicFormUrl) {
				this.openDrawer();
				return;
			}

			// If we are already loading the url or don't have the details to fetch the url, then ignore this action
			if (this.loadingDemographicFormUrl || !this.customerId || !this.proposedLoanId) {
				return;
			}

			try {
				this.loadingDemographicFormUrl = true;

				// fetch the demographic form url
				const service = AutoFactory.get(AnonymousBorrowerMinimumAccessService);
				const fetchedUrl = await service.create1071DemographicForm1071Url(this.customerId, this.proposedLoanId);

				// Format it since we need to inform it is in a nested window
				this.demographicFormUrl = this.formatUrl(fetchedUrl);

				this.openDrawer();
			} finally {
				this.loadingDemographicFormUrl = false;
			}
		},
		formatUrl(url: string) {
			if (!url) {
				return undefined;
			}

			if (url.includes('?')) {
				return url + `&origin=${this.origin}`;
			} else {
				return url + `?origin=${this.origin}`;
			}
		}
	}
});
