import { AutoFactory, DynamicApplicationsService, ApplicationTemplate } from '@sageworks/jpi';
import { defineModule } from 'direct-vuex';
import Vue from 'vue';
import { moduleActionContext } from '.';

// eslint-disable-next-line no-use-before-define
const actionContext = (context: any) => moduleActionContext(context, ApplicationTemplatesModule);

export interface ApplicationTemplatesModuleState {
	preAppTemplate: ApplicationTemplate | null;
	applicationTemplatesByType: {
		preApp: ApplicationTemplate[] | null;
		business: ApplicationTemplate[] | null;
		person: ApplicationTemplate[] | null;
		farm: ApplicationTemplate[] | null;
		sba: ApplicationTemplate[] | null;
		nonprofit: ApplicationTemplate[] | null;
	};
}
const ApplicationTemplatesModule = defineModule({
	namespaced: true,
	state: () => {
		return {
			preAppTemplate: null,
			applicationTemplatesByType: {
				preApp: null,
				business: null,
				person: null,
				farm: null,
				sba: null,
				nonprofit: null
			}
		} as ApplicationTemplatesModuleState;
	},
	mutations: {
		SET_PRE_APP_TEMPLATE(state, preAppTemplate: ApplicationTemplate | null) {
			state.preAppTemplate = preAppTemplate;
		},
		SET_TEMPLATES_BY_TYPE(state, { appType, templates }: { appType: ApplicationTemplate.TypeEnum; templates: ApplicationTemplate[] }) {
			Vue.set(state.applicationTemplatesByType, appType, templates);
		}
	},
	actions: {
		async fetchPreAppTemplate(context): Promise<void> {
			const { commit } = actionContext(context);
			const dynamicApplicationService = AutoFactory.get(DynamicApplicationsService);
			const types = [ApplicationTemplate.TypeEnum.PreApp];

			const response = await dynamicApplicationService.getPaged(undefined, undefined, undefined, true, types);

			if (response.items != null && response.items.length === 1) {
				commit.SET_PRE_APP_TEMPLATE(response.items[0] as ApplicationTemplate);
			} else {
				commit.SET_PRE_APP_TEMPLATE(null);
			}
		},
		async loadTemplatesByType(context, appType: ApplicationTemplate.TypeEnum): Promise<void> {
			const { commit } = actionContext(context);
			const dynamicApplicationsService = AutoFactory.get(DynamicApplicationsService);
			let templates = (await dynamicApplicationsService.getPaged(1, 200, undefined, undefined, [appType])).items || [];
			commit.SET_TEMPLATES_BY_TYPE({
				appType: appType,
				templates: templates
			});
		},
		async loadCurrentTemplateType(context) {
			const { dispatch, rootGetters } = actionContext(context);
			const type = rootGetters.ApplicationForm?.applicationTemplateType;
			if (type == null) return;
			await dispatch.loadTemplatesByType(type);
		}
	},
	getters: {
		preAppTemplateId(state): number {
			return state?.preAppTemplate?.id ?? 0;
		}
	}
});

export default ApplicationTemplatesModule;
