import { LoanRoleType } from '@sageworks/dynamic-forms';
import { AggregatedFormDataModel, LoanRoleDataModel, RelatedLoanRoleData } from '@sageworks/jpi';

export const locateRoles = (formData: AggregatedFormDataModel, loanRoleType: LoanRoleType) => {
	switch (loanRoleType) {
		case LoanRoleType.PrimaryBorrower:
			return formData?.primaryBorrowerMapping ? [formData.primaryBorrowerMapping] : [];
		case LoanRoleType.CoBorrower:
			return formData?.coBorrowerMappings ?? [];
		case LoanRoleType.CoSigner:
			return formData?.coSignerMappings ?? [];
		case LoanRoleType.CreditApplicant:
			return formData?.creditApplicantMappings ?? [];
		case LoanRoleType.Trustee:
			return formData?.trusteeMappings ?? [];
		case LoanRoleType.Guarantor:
			return formData?.guarantorMappings ?? [];
		default:
			return [];
	}
};

export const locateRole = (formData: AggregatedFormDataModel, id: number) => {
	for (let roleType of Object.values(LoanRoleType)) {
		const role = locateRoles(formData, roleType).find(role => role.dataObjectID === id);
		
		if (role) {
			return role;
		}
	}
	
	return undefined;
};

export const setRoles = (formData: AggregatedFormDataModel, loanRoleType: LoanRoleType, roles: LoanRoleDataModel[]) => {
	if (!formData) {
		return;
	}

	switch (loanRoleType) {
		case LoanRoleType.PrimaryBorrower:
			formData.primaryBorrowerMapping = roles.length > 0 ? roles[0] : undefined;
			break;
		case LoanRoleType.CoBorrower:
			formData.coBorrowerMappings = roles;
			break;
		case LoanRoleType.CoSigner:
			formData.coSignerMappings = roles;
			break;
		case LoanRoleType.CreditApplicant:
			formData.creditApplicantMappings = roles;
			break;
		case LoanRoleType.Trustee:
			formData.trusteeMappings = roles;
			break;
		case LoanRoleType.Guarantor:
			formData.guarantorMappings = roles;
			break;
	}
};

export const locateRelatedRoles = (formData: AggregatedFormDataModel, loanRoleType: LoanRoleType) => {
	switch (loanRoleType) {
		case LoanRoleType.AuthorizedSigner:
			return formData?.authorizedSigners ?? [];
		default:
			return [];
	}
};

export const setRelatedRoles = (formData: AggregatedFormDataModel, loanRoleType: LoanRoleType, roles: RelatedLoanRoleData[]) => {
	if (!formData) {
		return;
	}

	switch (loanRoleType) {
		case LoanRoleType.AuthorizedSigner:
			formData.authorizedSigners = roles;
			break;
	}
};