module.exports = function(ctx) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div>\n	';
 if (!ctx.isLoading) {  ;
__p += '\n		<div>\n			';
 if (ctx.authorizeDetails) {  ;
__p += '\n				<div class="font-italic" ref="authorizeAuditMessage">\n					Credit Report Authorized on ' +
((__t = ( ctx.authorizeDetails.date )) == null ? '' : __t) +
'\n				</div>\n			';
 } ;
__p += '\n			';
 if (ctx.pullCreditDetails) {  ;
__p += '\n				<div  class="font-italic" ref="pullAuditMessage">\n					Credit Report Pulled on ' +
((__t = ( ctx.pullCreditDetails.date )) == null ? '' : __t) +
'\n				</div>\n			';
 } ;
__p += '\n			';
 if (ctx.waiveDetails) {  ;
__p += '\n				<div  class="font-italic" ref="waiveAuditMessage">\n					Requirement waived by ' +
((__t = ( ctx.waiveDetails.name )) == null ? '' : __t) +
' on ' +
((__t = ( ctx.waiveDetails.date )) == null ? '' : __t) +
'\n				</div>\n			';
 } ;
__p += '\n		</div>\n		<div>\n			';
 if (ctx.showAuthorizeButton) {  ;
__p += '\n				<button class="btn btn-primary" ref="authorizeCreditButton" >Authorize Credit</button>\n			';
 } else if (ctx.showAuthorizeAndPullButton) {  ;
__p += '\n				<button class="btn btn-primary" ref="authorizeAndPullCreditButton">Authorize and Pull Credit</button>\n			';
 } else if (ctx.showDisabledAuthorizeButton) {  ;
__p += '\n				<button class="btn btn-primary not-allowed-cursor" ref="authorizeCreditButton" disabled>Authorize Credit</button>\n			';
 } ;
__p += '\n\n			';
 if (ctx.showPullCreditButton) {  ;
__p += '\n				<button class="btn btn-primary" ref="pullCreditButton">Pull Credit</button>\n			';
 } ;
__p += '\n			\n			';
 if (ctx.showWaiveButton) {  ;
__p += '\n				<button class="btn btn-outline-primary" ref="waiveCreditButton">Waive</button>\n			';
 } else if (ctx.showUnWaiveButton) { ;
__p += '\n				<button class="btn btn-outline-primary" ref="unwaiveCreditButton">Un-waive</button>\n			';
 } ;
__p += '\n		</div>\n	';
 } ;
__p += '\n</div>\n';
return __p
}