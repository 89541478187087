
import { Component } from 'vue-property-decorator';
import BaseFormBuilder from '../base-form-builder/base-form-builder.vue';
import { FormioBuilderOptionsSchema, FormioBuilderGroupSchema } from '../../formio-interfaces/FormioBuilderOptionsSchema';
import { OnlinePortalWidgetBuilderOptionsSchema } from '../../formio-interfaces/OnlinePortalWidgetBuilderOptionsSchema';
import { ApplicationTemplate, FeatureFlagging } from '@sageworks/jpi';
import { BuilderGroupFactory } from '../../utils/builder';
import { getEntitySubwidgets, getWidgetGroupsForEntityInfo } from './entity-subwidget-helper';
import { getWidgetType, getAppType, getApplicationTemplateId } from '../../utils/form-path-utils';
import { ValidationBuilder } from '../../utils/validation-builder';
import { TemplateDataField, CustomComponentType, CustomBuilderGroups, CustomComponentLabel, FinancialSubaccountCustomComponentTypes } from '../../enums';
import { FormPathUtils } from '../../utils';

@Component
export default class WidgetBuilder extends BaseFormBuilder {
	get builder(): FormioBuilderOptionsSchema {
		const builderGroupFactory = new BuilderGroupFactory(this.properties ?? [], this.widgetType, this.applicationTemplate);
		const hasPlaidFeatureFlag = this.featureFlags.some(x => x === FeatureFlagging.FeatureFlagEnum.EnablePlaidInDya) ?? false;

		const builder = {
			// TODO: Move getEntitySubwidgets and getWidgetGroupsForEntityInfo into BuilderGroupFactory
			...getEntitySubwidgets(this.widgetType, this.appType, this.templateDataFieldMapping, this.properties || [], hasPlaidFeatureFlag),
			...getWidgetGroupsForEntityInfo(this.widgetType, this.appType, this.templateDataFieldMapping, this.properties || [], hasPlaidFeatureFlag),
			[CustomBuilderGroups.udFields]: builderGroupFactory.createCustomFieldComponentBuilderGroup(),
			[CustomBuilderGroups.defaultFields]: builderGroupFactory.createDefaultFieldComponentBuilderGroup(
				undefined,
				undefined,
				this.hasProduct('fairLending1071') ?? false
			)
		} as OnlinePortalWidgetBuilderOptionsSchema;

		Object.keys(builder)
			.map(groupKey => (builder as any)[groupKey] as FormioBuilderGroupSchema)
			.sort((groupA, groupB) => groupA.weight - groupB.weight)[0].default = true;
		return builder;
	}

	get additionalOptions() {
		return {
			customComponentType: this.widgetType
		};
	}

	get applicationTemplate() {
		return {
			id: this.applicationTemplateId,
			type: this.appType
		} as ApplicationTemplate;
	}

	get appType() {
		return getAppType(this.formPath);
	}

	get widgetType(): CustomComponentType | null {
		return getWidgetType(this.formPath);
	}

	get applicationTemplateId() {
		return getApplicationTemplateId(this.formPath);
	}

	protected validateForm(): string[] {
		const validationBuilder = new ValidationBuilder(this.form.components);

		if (FinancialSubaccountCustomComponentTypes.includes(this.widgetType!)) {
			this.addFinancialSubaccountValidation(validationBuilder);
		}

		switch (this.widgetType) {
			case CustomComponentType.personalInfo:
				this._addPersonalInfoValidations(validationBuilder);
				break;
			case CustomComponentType.businessInfo:
				this._addBusinessInfoValidations(validationBuilder);
				break;
			case CustomComponentType.collateralInfo:
				this._addCollateralInfoValidations(validationBuilder);
				break;
			case CustomComponentType.nonprofitInfo:
				this._addNonprofitInfoValidations(validationBuilder);
				break;
			default:
				this._addEntityTypeInfoValidations(validationBuilder);
				break;
		}

		return validationBuilder.buildValidations();
	}

	private _addPersonalInfoValidations(validationBuilder: ValidationBuilder): void {
		validationBuilder.validateForMinUsages(
			1,
			{
				templateDataFieldId: TemplateDataField.PersonalFirstName
			},
			'Personal Info must include the First Name field'
		);

		validationBuilder.validateForMinUsages(
			1,
			{
				templateDataFieldId: TemplateDataField.PersonalLastName
			},
			'Personal Info must include the Last Name field'
		);
	}

	// eslint-disable-next-line max-lines-per-function
	private _addBusinessInfoValidations(validationBuilder: ValidationBuilder): void {
		validationBuilder.validateForMinUsages(
			1,
			{
				templateDataFieldId: TemplateDataField.BusinessName
			},
			'Business Info must include the Business Name field'
		);

		validationBuilder.validateForMinUsages(
			1,
			{
				templateDataFieldId: TemplateDataField.BusinessState
			},
			'Business Info must include the State field'
		);

		validationBuilder.validateForMinUsages(
			1,
			{
				templateDataFieldId: TemplateDataField.BusinessIndustryCode
			},
			'Business Info must include the Industry Code field'
		);

		const allowedLoanRolesForApplicantBusiness = [
			CustomComponentType.primaryBorrowerEntity,
			CustomComponentType.coBorrowerEntity,
			CustomComponentType.guarantorEntity
		];
		if (this.appType === ApplicationTemplate.TypeEnum.Sba && FormPathUtils.isInWidget(this.formPath, allowedLoanRolesForApplicantBusiness)) {
			const componentLabel = CustomComponentLabel()[CustomComponentType.applicantBusinessQuestion];
			validationBuilder.validateForExactUsages(
				1,
				{ type: CustomComponentType.applicantBusinessQuestion },
				`Business Info for the SBA Application Template must include one and only one "${componentLabel}" widget`
			);
		} else if (this.appType === ApplicationTemplate.TypeEnum.Sba) {
			const componentLabel = CustomComponentLabel()[CustomComponentType.applicantBusinessQuestion];
			validationBuilder.validateForMaxUsages(
				1,
				{ type: CustomComponentType.applicantBusinessQuestion },
				`Business Info for the SBA Application Template can only include one "${componentLabel}" widget`
			);
		}
	}

	private _addCollateralInfoValidations(validationBuilder: ValidationBuilder): void {
		validationBuilder.validateForMinUsages(
			1,
			{
				templateDataFieldId: TemplateDataField.CollateralDescription
			},
			'Collateral Info must include the Collateral Description field'
		);

		validationBuilder.validateForMinUsages(
			1,
			{
				templateDataFieldId: TemplateDataField.CollateralPledgedBy
			},
			'Collateral Info must include the Pledged By field'
		);
	}

	private _addNonprofitInfoValidations(validationBuilder: ValidationBuilder): void {
		validationBuilder.validateForMinUsages(
			1,
			{
				templateDataFieldId: TemplateDataField.NonprofitName
			},
			'Nonprofit Info must include the Nonprofit Name field'
		);

		validationBuilder.validateForMinUsages(
			1,
			{
				templateDataFieldId: TemplateDataField.NonprofitState
			},
			'Nonprofit Info must include the State field'
		);

		validationBuilder.validateForMinUsages(
			1,
			{
				templateDataFieldId: TemplateDataField.NonprofitSectorCode
			},
			'Nonprofit Info must include the Sector Code field'
		);
	}

	private _addEntityTypeInfoValidations(validationBuilder: ValidationBuilder): void {
		validationBuilder.validateForMaxUsages(
			1,
			{
				type: CustomComponentType.nonprofitInfo
			},
			'Nonprofit Info cannot be included more than once'
		);

		validationBuilder.validateForMaxUsages(
			1,
			{
				type: CustomComponentType.personalInfo
			},
			'Personal Info cannot be included more than once'
		);

		validationBuilder.validateForMaxUsages(
			1,
			{
				type: CustomComponentType.businessInfo
			},
			'Business Info cannot be included more than once'
		);
	}

	private ignoreField(templateDataFieldId: number | null | undefined): boolean {
		const fieldsToIgnore: TemplateDataField[] = [
			TemplateDataField.SubaccountCustomer,
			TemplateDataField.SubaccountFinancialDataId,
			TemplateDataField.SubaccountFinancialStatement
		];

		return templateDataFieldId == null ? false : fieldsToIgnore.includes(templateDataFieldId);
	}

	private addFinancialSubaccountValidation(validationBuilder: ValidationBuilder): void {
		const dataFieldSchemas = this.builder.defaultFields.components;
		Object.keys(dataFieldSchemas).forEach(_key => {
			const schema = dataFieldSchemas[_key].schema;
			const validationQuery =
				schema.dataFieldId != null ? { dataFieldId: dataFieldSchemas[_key].schema.dataFieldId } : { key: dataFieldSchemas[_key].schema.key };

			validationBuilder.validateForExactUsages(1, validationQuery, `You must include one and only one ${dataFieldSchemas[_key].title} field`);
		});
	}
}
