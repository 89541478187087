export enum FormioTemplate {
	FormioColumns = 'formio-columns',
	FormioFieldset = 'formio-fieldset',
	FormioForm = 'formio-form',
	FormioHtml = 'formio-html',
	FormioContent = 'formio-content',
	FormioInput = 'formio-input',
	FormioPanel = 'formio-panel',
	FormioTable = 'formio-table',
	FormioTabs = 'formio-tabs',
	FormioWell = 'formio-well',
	FormioRoleRepeater = 'formio-role-repeater',
	FormioCreditReport = 'formio-credit-report',
	FormioRelatedRoleRepeater = 'formio-related-role-repeater',
	FormioEntityInfoForm = 'formio-entity-info-form',
	FormioBusinessInvestments = 'formio-business-investments'
}
