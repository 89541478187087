import { CustomComponentType } from '../../enums/custom-component-type';
import { ProposedProduct, ApplicationTemplate } from '@sageworks/jpi';
import { TemplateDataField } from '../../enums';

export function businessProductSelectSchema() {
	return {
		title: 'Business Application Product',
		type: CustomComponentType.proposedProductSelect,
		weight: 20,
		schema: {
			label: 'What kind of business loan are you looking for?',
			type: CustomComponentType.proposedProductSelect,
			appType: ProposedProduct.ApplicationTypesEnum.Business,
			forceRequired: true
		}
	};
}

export function personProductSelectSchema() {
	return {
		title: 'Personal Application Product',
		type: CustomComponentType.proposedProductSelect,
		weight: 30,
		schema: {
			label: 'What kind of personal loan are you looking for?',
			type: CustomComponentType.proposedProductSelect,
			appType: ProposedProduct.ApplicationTypesEnum.Person,
			forceRequired: true
		}
	};
}

export function farmProductSelectSchema() {
	return {
		title: 'Farm Application Product',
		type: CustomComponentType.proposedProductSelect,
		weight: 40,
		schema: {
			label: 'What kind of Farm loan are you looking for?',
			type: CustomComponentType.proposedProductSelect,
			appType: ProposedProduct.ApplicationTypesEnum.Farm,
			forceRequired: true
		}
	};
}

export function nonprofitProductSelectSchema() {
	return {
		title: 'Nonprofit Application Product',
		type: CustomComponentType.proposedProductSelect,
		weight: 50,
		schema: {
			label: 'What kind of nonprofit loan are you looking for?',
			type: CustomComponentType.proposedProductSelect,
			appType: ProposedProduct.ApplicationTypesEnum.Nonprofit,
			forceRequired: true
		}
	};
}

export function sbaProductSelectSchema() {
	return {
		title: 'SBA Application Product',
		type: CustomComponentType.proposedProductSelect,
		weight: 60,
		schema: {
			label: 'What kind of SBA loan are you looking for?',
			type: CustomComponentType.proposedProductSelect,
			appType: ProposedProduct.ApplicationTypesEnum.Sba,
			forceRequired: true
		}
	};
}

export function loanAmountFieldSchema() {
	return {
		title: 'Loan Amount',
		type: 'currency',
		weight: 80,
		schema: {
			label: 'What loan amount are you looking for?',
			type: CustomComponentType.extendedCurrency,
			// Custom property to identify field in validation
			fieldType: 'LoanAmountInput',
			templateDataFieldId: TemplateDataField.ProposedLoanLoanAmount
		}
	};
}

export function zipCodeFieldSchema() {
	return {
		title: 'Zip Code',
		weight: 90,
		schema: {
			label: 'Zip Code',
			type: CustomComponentType.extendedTextField,
			// Custom property to identify field in validation
			fieldType: 'ZipCode',
			validate: {
				custom: "var validZips = [];\nvalid = validZips.length === 0 || validZips.includes(input) ? true : 'Invalid zip code';"
			}
		}
	};
}

// eslint-disable-next-line max-lines-per-function
export function citizenshipStatusFieldSchema(templateDataFieldMapping: { [templateDataFieldId: number]: number }) {
	return {
		title: 'Citizenship Status',
		weight: 70,
		schema: {
			label: 'Citizenship Status',
			type: CustomComponentType.extendedSelectField,
			// Custom property to identify field in validation
			fieldType: 'CitizenshipStatus',
			validate: {
				custom:
					// eslint-disable-next-line max-len
					"var validCitizenshipStatuses = [];\nvalid = validCitizenshipStatuses.length === 0 || validCitizenshipStatuses.includes(input) ? true : 'Invalid citizenship status';"
			},
			data: {
				// eslint-disable-next-line no-useless-escape, max-len
				url: `{{Formio.apiUrl}}/v1/{{ ((instance.builderMode || builderTemplateHelpers.isInPreviewMode(instance)) ? \"\" : \"online-portal/\") }}property-options/${
					templateDataFieldMapping[TemplateDataField.CitizenshipStatus]
				}?perPage={{limit}}&page={{page + 1}}`,
				headers: [
					{
						key: 'authorization',
						value: 'Bearer {{Formio.apiToken}}'
					}
				]
			},
			selectValues: 'items',
			searchField: 'label',
			valueProperty: 'value',
			dataSrc: 'url',
			lazyLoad: false
		}
	};
}

export function widgetComponentsSchema(
	subscribedApplicationTypes: ApplicationTemplate.TypeEnum[],
	templateDataFieldMapping: { [templateDataFieldId: number]: number }
) {
	let widgetComponentsSchema: any = {
		loanAmount: loanAmountFieldSchema(),
		zipCode: zipCodeFieldSchema(),
		citizenshipStatus: citizenshipStatusFieldSchema(templateDataFieldMapping)
	};

	subscribedApplicationTypes.forEach(type => {
		switch (type) {
			case ApplicationTemplate.TypeEnum.Business:
				widgetComponentsSchema.proposedProductBusiness = businessProductSelectSchema();
				break;
			case ApplicationTemplate.TypeEnum.Person:
				widgetComponentsSchema.proposedProductPerson = personProductSelectSchema();
				break;
			case ApplicationTemplate.TypeEnum.Farm:
				widgetComponentsSchema.proposedProductFarm = farmProductSelectSchema();
				break;
			case ApplicationTemplate.TypeEnum.Nonprofit:
				widgetComponentsSchema.proposedProductNonprofit = nonprofitProductSelectSchema();
				break;
			case ApplicationTemplate.TypeEnum.Sba:
				widgetComponentsSchema.proposedProductSba = sbaProductSelectSchema();
				break;
		}
	});

	return widgetComponentsSchema;
}
