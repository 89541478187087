import { DataField, Customer } from '@sageworks/jpi';
import { TemplateDataField } from '../../../enums';
import { formatDataFieldIdKey } from '../simple-inputs/extended-field-helper';

export function buildExistingCustomerSubmissionObject(customer: Customer, dataFields: DataField[]): any {
	if (customer == null || dataFields == null) {
		throw Error('Must provide both customer and dataFields');
	}

	const submissionObject: { [key: string]: any } = {
		data: {}
	};

	submissionObject.data.id = customer.id;

	dataFields.forEach((dataField: DataField) => {
		submissionObject.data[formatDataFieldIdKey(dataField.id!.toString())] = getDataValueByTemplateDataField(
			customer,
			dataField.templateDataFieldId as TemplateDataField
		);
	});

	return submissionObject;
}

export function getDataValueByTemplateDataField(customer: Customer, templateDataField: TemplateDataField) {
	switch (templateDataField) {
		case TemplateDataField.PersonalFirstName:
			return customer.firstName;
		case TemplateDataField.PersonalLastName:
			return customer.lastName;
		case TemplateDataField.PersonalEmail:
			return customer.emailAddress;
		case TemplateDataField.BusinessState:
		case TemplateDataField.FarmState:
			return customer.stateCode;
		case TemplateDataField.BusinessName:
		case TemplateDataField.FarmName:
			return customer.name;
		case TemplateDataField.BusinessIndustryCode:
		case TemplateDataField.FarmIndustryCode:
			return customer.industryCode;
		case TemplateDataField.BusinessSubEntityType:
			return customer.entryMethod;
		default:
			throw Error('An unsupported TemplateDataField was supplied when building the data object');
	}
}
