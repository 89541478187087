export enum ContextDataProperty {
	IsJpiAuthenticated = 'jpiAuthenticated',
	LoanApplicationId = 'applicationId',
	CurrentUserInfo = 'userInfo',
	LoanApplicationType = 'applicationType',
	IsLender = 'isLender',
	LenderOnlyMode = 'isInLenderOnlyMode',
	vueInstance = 'vueInstance',
	PropertiesByType = 'propertiesByType',
	RenderMode = 'opnRenderMode'
}
