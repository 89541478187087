
import Vue, { PropType } from 'vue';
import { ApplicationValidationStage } from '@/enums';
import ApplicationValidationStep from './application-validation-modal-step.vue';
import { ApplicationSubmitValidationResults } from '@/utils/application-validation';
import {UsableProducts} from '@sageworks/jpi';

export default Vue.extend({
	name: 'ApplicationValidationSteps',
	components: {
		ApplicationValidationStep
	},
	props: {
		isSubmitting: Boolean,
		isSendingESignEmails: Boolean,
		isLender: Boolean,
		submissionSuccessful: Boolean,
		eSignEmailSendingSucceeded: Boolean,
		customerCanUseEsign: Boolean,
		currentValidationStage: Number as PropType<ApplicationValidationStage>,
		validationResults: Object as PropType<ApplicationSubmitValidationResults>,
		isBypassingSignatures: Boolean
	},
	data() {
		return {
			ApplicationValidationStage: ApplicationValidationStage
		};
	},
	computed: {
		hasLosLiteSubscription(): boolean | undefined {
			return this.directStore.state.UsableProducts.usableProducts?.has('abrigoCommunityLending' as UsableProducts.ProductsEnum);
		},
		applicationRequestLabel(): string {
			return this.hasLosLiteSubscription ? 'Request Form' : 'Application';
		},
	},
});
