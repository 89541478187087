import { defineModule, localActionContext } from 'direct-vuex';
import { AutoFactory, OnlinePortalDocumentNamesService, DocumentName } from '@sageworks/jpi';
import { BootstrapVueSelectOption } from '@/models';

// eslint-disable-next-line no-use-before-define
const actionContext = (context: any) => localActionContext(context, DocumentNameModule);

export interface DocumentNameModuleState {
	documentNames: DocumentName[];
}
const DocumentNameModule = defineModule({
	namespaced: true,
	state: () => {
		return {
			documentNames: []
		} as DocumentNameModuleState;
	},
	mutations: {
		SET_DOCUMENT_NAME_OPTIONS(state, newDocumentNames: DocumentName[]) {
			state.documentNames = newDocumentNames;
		}
	},
	actions: {
		async loadDocumentNames(context): Promise<void> {
			const { commit } = actionContext(context);

			const documentNameService = AutoFactory.get(OnlinePortalDocumentNamesService);
			var documentNames = await documentNameService.getPaged(1, 1000);

			documentNames.items = documentNames.items?.sort((name1, name2) => (name1.name as string).localeCompare(name2.name as string));

			commit.SET_DOCUMENT_NAME_OPTIONS(documentNames.items || []);
		}
	},
	getters: {
		documentNameById: state => (documentNameId: number) => {
			return state.documentNames.filter(documentName => documentName.id === documentNameId)[0];
		},
		documentNameSelectOptions: state => (isLender: boolean) => {
			return state.documentNames.map((name: DocumentName) => {
				return {
					// If we're a borrower, we want the customer-facing name unless there isn't one defined, in which case we use the regular name
					text: !isLender && name.customerFacingName != null && name.customerFacingName !== '' ? name.customerFacingName : name.name,
					value: name.id
				} as BootstrapVueSelectOption;
			});
		}
	}
});

export default DocumentNameModule;
