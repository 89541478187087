import { AutoFactory, OnlinePortalRegulation1071StatusService } from '@sageworks/jpi';
import { BeneficialOwnership1071Status, BeneficialOwnership1071StatusByCustomerId } from './beneficial-ownership-1071-status';

export const fetchBeneficialOwnership1071Status = async (loanId: number) => {
	const service = AutoFactory.get(OnlinePortalRegulation1071StatusService);
	const response = await service.getStatusesForProposedLoan(loanId);

	return response as BeneficialOwnership1071StatusByCustomerId;
};

export const fetchBeneficialOwnership1071StatusByCustomerId = async (loanId: number, customerId: number) => {
	const service = AutoFactory.get(OnlinePortalRegulation1071StatusService);
	const response = await service.getStatusForProfitEntityOnProposedLoan(loanId, customerId);

	return response as BeneficialOwnership1071Status;
};