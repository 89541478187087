import { CustomComponentType, ContextDataProperty } from '../../../../enums';
import Component from 'formiojs/components/_classes/component/Component';
import { authorizeCreditPull } from '../helpers/credit-report-authorization-api-helper';
import { LoanApplicationCreditAuthorization } from '@sageworks/jpi';
import { getContextDataValue } from '../../../../utils/context-data-helper/context-data-helper';
import { IPopupBodyComponent } from '../../popup';

export default class CreditReportAuthorizationInput extends Component implements IPopupBodyComponent {
	private get authorizationMessage() {
		return this.component.authorizationMessage;
	}
	private get customerId() {
		return this.component.customerId;
	}
	private get authorizationMetadataId(): number | undefined {
		return this.component.authorizationMetadataId as number | undefined;
	}
	public preClose(): Promise<void> {
		return Promise.resolve();
	}

	public static schema(...extend: any) {
		return Component.schema(
			{
				label: 'Credit Report',
				type: CustomComponentType.creditReportAuthorizationInput,
				key: CustomComponentType.creditReportAuthorizationInput,
				authorizationMessage: '',
				authorizationMetadataId: undefined
			},
			...extend
		);
	}

	public resultPromise!: Promise<void>;
	public isLoading = false;
	public authorizationMetadata!: LoanApplicationCreditAuthorization | null;

	private resultPromiseResolve!: (result: any) => void;

	public init() {
		this.authorizationMetadata = null;
		this.resultPromise = new Promise(resolve => (this.resultPromiseResolve = resolve));
	}

	public render(): any {
		return this.renderTemplate('dialogCreditReportAuthorize', { name: this.name, message: this.authorizationMessage, isLoading: this.isLoading });
	}

	public async attach(element: any) {
		this.loadRefs(element, {
			dialogAuthorizeCreditButton: 'single',
			dialogAuthorizeCreditCheckbox: 'single',
			dialogNeedAgreementMessage: 'single'
		});

		this.addEventListener(this.refs.dialogAuthorizeCreditButton as any, 'click', this.onDialogAuthorizeCreditButtonClick);

		return super.attach(element);
	}

	/**
	 *  Arrow functions to preserve `this` property
	 */

	// eslint-disable-next-line max-lines-per-function
	private onDialogAuthorizeCreditButtonClick = async () => {
		const { dialogAuthorizeCreditCheckbox, dialogNeedAgreementMessage } = this.refs as any;
		let jpiAuthentication = getContextDataValue(this, ContextDataProperty.IsJpiAuthenticated);
		let userInfo = getContextDataValue(this, ContextDataProperty.CurrentUserInfo);

		if (!dialogAuthorizeCreditCheckbox.checked) {
			this.showAuthorizationError(dialogNeedAgreementMessage);
		}
		// Check if we are authorized
		else if (!jpiAuthentication) {
			return;
		} else if (userInfo && userInfo.userId && !userInfo.proxyUserId) {
			this.isLoading = true;
			this.redraw();

			try {
				let loanApplicationId = getContextDataValue(this, ContextDataProperty.LoanApplicationId);
				const result = await authorizeCreditPull(
					this.authorizationMetadataId,
					loanApplicationId,
					this.customerId,
					userInfo?.userId,
					LoanApplicationCreditAuthorization.AuthorizedByUserTypeEnum.BankCustomerUser
				);

				this.authorizationMetadata = result;
				this.resultPromiseResolve(this.authorizationMetadata);
			} finally {
				this.isLoading = false;
				this.redraw();
			}
		} else {
			// Somehow the user is logged in, but isn't authenticated against the JPI.
			// If that's true, we've hit some crazy corner case and the user needs to refresh the page.
			// In production, this is practically impossible since not being authenticated should force
			// you to the log in page.
			throw { message: 'User is not authorized somehow', userInfo, jpiAuthentication };
		}
	};

	private showAuthorizationError(dialogNeedAgreementMessage: any): void {
		dialogNeedAgreementMessage.classList.remove('d-none');
	}
}
