import Vue from 'vue';
import Vuex from 'vuex';
import { InitializeAppUtils } from '@/utils';
import { createDirectStore } from 'direct-vuex';
import ApplicationDocumentRequestModule from './application-document-request-module';
import AdobeESignModule from './AdobeESignModule';
import ApplicationTemplatesModule from './ApplicationTemplatesModule';
import AppConfigModule from './AppConfigModule';
import ApplicationModule from './ApplicationModule';
import AuthenticationModule from './AuthenticationModule';
import BorrowerAccessRestrictionsModule from './BorrowerAccessRestrictionsModule';
import ContactInformationModule from './ContactInformationModule';
import DocumentRequestModule from './DocumentRequestModule';
import DocumentNameModule from './DocumentNameModule';
import DocumentSettingsModule from './DocumentSettingsModule';
import UserCreatedMessagesModule from './UserCreatedMessagesModule';
import DocumentModule from './DocumentModule';
import CustomComponentPropertiesModule from './CustomComponentPropertiesModule';
import FormCacheModule from './FormCacheModule';
import InstitutionSettingsModule from './InstitutionSettingsModule';
import LoginModule from './LoginModule';
import SbaFormModule from './SbaFormModule';
import SessionActivityModule from './SessionActivityModule';
import StartApplicationModule from './StartApplicationModule';
import StylingSettingsModule from './StylingSettingsModule';
import TermsAndConditionsModule from './TermsAndConditionsModule';
import UserModule from './UserModule';
import WindowInfoModule from './WindowInfoModule';
import FeatureFlagModule from './FeatureFlagModule';
import LoanApplicationMetadataModule from './LoanApplicationMetadataModule';
import LoanApplicationDataModule from './LoanApplicationDataModule';
import MultiLoanApplicationFormModule from './MultiLoanApplicationFormModule';
import PDFPreviewModule from './PDFPreviewModule';
import LenderAuthorizationModule from './LenderAuthorizationModule';
import UsableProductsModule from '@/store/UsableProductsModule';
import PlaidModule from './PlaidModule';

import { sessionActivityPlugin } from './plugins/SessionActivityPlugin';
import HmdaGmiFormModule from './HmdaGMIFormModule';

InitializeAppUtils.enableDevtoolsIfDevEnvironment();

Vue.use(Vuex);

// eslint-disable-next-line max-lines-per-function
export function getDefaultStoreDefinition() {
	return {
		modules: {
			AdobeESignModule: AdobeESignModule || {},
			AppConfig: AppConfigModule || {},
			Application: ApplicationModule || {},
			ApplicationTemplates: ApplicationTemplatesModule || {},
			Authentication: AuthenticationModule || {},
			ApplicationDocumentRequest: ApplicationDocumentRequestModule || {},
			BorrowerAccessRestrictions: BorrowerAccessRestrictionsModule || {},
			ContactInformation: ContactInformationModule || {},
			CustomComponentProperties: CustomComponentPropertiesModule || {},
			Document: DocumentModule || {},
			DocumentName: DocumentNameModule || {},
			DocumentRequest: DocumentRequestModule || {},
			DocumentSettings: DocumentSettingsModule || {},
			UserCreatedMessages: UserCreatedMessagesModule || {},
			FeatureFlag: FeatureFlagModule || {},
			FormCache: FormCacheModule || {},
			HmdaGmi: HmdaGmiFormModule || {},
			InstitutionSettings: InstitutionSettingsModule || {},
			Login: LoginModule || {},
			SbaForm: SbaFormModule || {},
			SessionActivity: SessionActivityModule || {},
			StartApplication: StartApplicationModule || {},
			StylingSettings: StylingSettingsModule || {},
			TermsAndConditions: TermsAndConditionsModule || {},
			User: UserModule || {},
			WindowInfo: WindowInfoModule || {},
			LoanApplicationMetadata: LoanApplicationMetadataModule || {},
			LoanApplicationData: LoanApplicationDataModule || {},
			MultiLoanApplicationForm: MultiLoanApplicationFormModule || {},
			PDFPreviewModule: PDFPreviewModule || {},
			LenderAuthorizationModule: LenderAuthorizationModule || {},
			UsableProducts: UsableProductsModule || {},
			Plaid: PlaidModule || {}
		},
		plugins: [sessionActivityPlugin]
	};
}

const { store, moduleActionContext, moduleGetterContext } = createDirectStore(getDefaultStoreDefinition());

export default store;
export { moduleActionContext, moduleGetterContext };

// Make the types of our vuex store modules available for the rest of the application
export type StoreType = typeof store;
declare module 'vuex' {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	interface Store<S> {
		direct: StoreType;
	}
}
declare module 'vue/types/vue' {
	interface Vue {
		directStore: StoreType;
	}
}
