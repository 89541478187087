
import { Component, Mixins } from 'vue-property-decorator';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { OnlinePortalDocumentRequest } from '@/models';
import { RouteMixin, ScaleMixin } from '@/mixins';
import { getStatusMessageForDocumentRequest } from '@/logic/DocumentRequest.service';
import { DocumentUpload } from '@/components';

@Component({
	components: {
		FontAwesomeIcon,
		DocumentUpload
	}
})
export default class DocumentDetails extends Mixins(RouteMixin, ScaleMixin) {
	private invalidRequest: boolean = true;
	private uploadedFile: File | null = null;
	private documentRequest: OnlinePortalDocumentRequest = {} as OnlinePortalDocumentRequest;

	get customerFacingName(): string {
		const req = this.documentRequest;
		var availableName = req.customerFacingName ? req.customerFacingName : req.documentName;
		return availableName || '';
	}

	public async beforeMount(): Promise<void> {
		if (this.directStore.state.DocumentRequest.currentPageDocumentRequests.length === 0) {
			await this.directStore.dispatch.DocumentRequest.loadPagedDocumentRequests({ page: 1, perPage: 20 });
		}
		let currentDocumentRequestId = Number(this.$route.params.documentRequestId);
		let docRequestsMatchingId = this.directStore.state.DocumentRequest.currentPageDocumentRequests.filter(
			request => request.id === currentDocumentRequestId
		);
		if (docRequestsMatchingId.length > 0) {
			this.invalidRequest = false;
			this.documentRequest = docRequestsMatchingId[0];
		}

		this.docStatusMessage = getStatusMessageForDocumentRequest(this.documentRequest);
	}
}
