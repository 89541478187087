import Component from 'formiojs/components/_classes/component/Component';
import { ContextDataProperty, CustomComponentType, TemplateDataField, CustomerComponentType, LoanRoleType } from '../../../enums';
import { getContextDataValue } from '../../../utils/context-data-helper/context-data-helper';
import { convertCustomerTypeToEntityType } from '../../../utils/customer-type-utils/customer-type-utils';
import { IPopupBodyComponent } from '../popup';
import { AutoFactory, CustomersService, ApplicationTemplate, Customer, DataField, LoanApplication } from '@sageworks/jpi';
import { buildExistingCustomerSubmissionObject } from './add-customer-data-field-helper';
import { AddCustomerResult } from './add-customer-result';
import CustomerSearch from '../customer-search/customer-search';
import AddNewCustomer from './add-new-customer/add-new-customer';
import EventEmitter from 'formiojs/EventEmitter';

export enum PopupState {
	MainMenu = 'MainMenu',
	AddNewEntityMapping = 'AddNewEntityMapping',
	EditEntity = 'EditEntity'
}

export enum AddType {
	AddNew = 'AddNew',
	AddExisting = 'AddExisting',
	EditExisting = 'EditExisting'
}

export default class AddCustomerPopup extends Component implements IPopupBodyComponent {
	public resultPromise!: Promise<any>;
	public resultPromiseResolve: any;
	protected addNewEntityDataFields: DataField[];
	protected customerSearch: CustomerSearch;
	protected popupState: PopupState;
	protected addCustomerForm: AddNewCustomer;

	constructor(component: any, options: any, data: object) {
		super(component, options, data);

		this.events = new EventEmitter({});

		this.addNewEntityDataFields = this.component.addNewEntityDataFields;
		this.popupState = this.getInitialPopupState(options);
		this.customerSearch = this.createCustomerSearch();
		this.addCustomerForm = this.createAddCustomerForm();
	}

	public getInitialPopupState(options: any): PopupState {
		if (this.bypassCustomerSearch) {
			return PopupState.AddNewEntityMapping;
		} else if (options.editMode) {
			return PopupState.EditEntity;
		} else if ((this.applicationEntities == null || this.applicationEntities.length === 0) && !this.isLender) {
			return PopupState.AddNewEntityMapping;
		} else {
			return PopupState.MainMenu;
		}
	}

	public preClose = () => {
		return Promise.resolve();
	};

	protected createCustomerSearch(): CustomerSearch {
		return new CustomerSearch(
			CustomerSearch.schema({ ignoredCustomerIds: this.component.ignoredCustomerIds }),
			{ ...this.options, events: this.events },
			{}
		);
	}

	protected createAddCustomerForm(): AddNewCustomer {
		return new AddNewCustomer(
			AddNewCustomer.schema(this.options.editMode, this.component.addNewEntityDataFields, this.applicationType),
			{ ...this.options, events: this.events },
			this.data
		);
	}

	protected get userInfo() {
		return getContextDataValue(this, ContextDataProperty.CurrentUserInfo);
	}

	protected get applicationType() {
		return getContextDataValue(this, ContextDataProperty.LoanApplicationType);
	}

	protected get bypassCustomerSearch() {
		return this.applicationType === LoanApplication.TypeEnum.Farm && this.roleType === LoanRoleType.AuthorizedSigner;
	}

	protected get isLender() {
		return this.userInfo == null || this.userInfo?.proxyUserId != null;
	}

	protected get roleType() {
		return this.component.roleType;
	}

	protected get applicationEntities() {
		return this.component.applicationEntities;
	}

	protected get personDataFields(): DataField[] {
		const templateDataFieldNameMapping: TemplateDataField[] = [
			TemplateDataField.PersonalFirstName,
			TemplateDataField.PersonalLastName,
			TemplateDataField.PersonalEmail
		];

		return this.filterDataFields(templateDataFieldNameMapping);
	}

	protected get businessDataFields(): DataField[] {
		const templateDataFieldNameMapping: TemplateDataField[] = [
			TemplateDataField.BusinessName,
			TemplateDataField.BusinessState,
			TemplateDataField.BusinessIndustryCode
		];

		return this.filterDataFields(templateDataFieldNameMapping);
	}

	protected get farmDataFields(): DataField[] {
		const templateDataFieldNameMapping: TemplateDataField[] = [TemplateDataField.FarmName];

		return this.filterDataFields(templateDataFieldNameMapping);
	}

	protected get modalTitle(): string {
		return `${this.popupState === PopupState.EditEntity ? 'Edit' : 'Add New'} ${this.roleType}`;
	}

	protected get componentTitle(): string {
		return 'Add Customer';
	}

	protected filterDataFields(allowedFields: TemplateDataField[]): DataField[] {
		return this.addNewEntityDataFields.filter(field => allowedFields.includes(field.templateDataFieldId as TemplateDataField));
	}

	public static schema(...extend: any) {
		return Component.schema(
			{
				label: 'Add Customer',
				type: CustomComponentType.addCustomerPopup,
				key: CustomComponentType.addCustomerPopup,
				roleType: null,
				input: false
			},
			...extend
		);
	}

	public init() {
		super.init();
		this.resultPromise = new Promise(r => (this.resultPromiseResolve = r));
	}

	public render(): any {
		return this.renderTemplate('addCustomerPopup', {
			title: this.componentTitle,
			isLender: this.isLender,
			modalTitle: this.modalTitle,
			popupState: this.popupState,
			addCustomerDataFields: this.addCustomerForm.render(),
			applicationEntities: this.applicationEntities,
			customerSearch: this.customerSearch.render(),
			bypassCustomerSearch: this.bypassCustomerSearch
		});
	}

	public async attach(element: any) {
		await super.attach(element);

		this.loadRefs(element, {
			addNewCustomerButton: 'single',
			saveNewEntityButton: 'single',
			cancelButton: 'single',
			addExistingEntity: 'multiple',
			addNewEntityField: 'multiple',
			customerSearch: 'single',
			modal: 'single',
			addCustomerForm: 'single'
		});

		this.attachEventListeners();

		if (this.refs.customerSearch != null) {
			await this.customerSearch.attach(this.refs.customerSearch);
			this.on('customerSearchSelect', (selectedCustomer: Customer) => this.addFromCustomerSearch(selectedCustomer), false);
		}

		if (this.refs.addCustomerForm != null) {
			await this.addCustomerForm.attach(this.refs.addCustomerForm);
		}
	}

	protected attachEventListeners() {
		const { addNewCustomerButton, saveNewEntityButton, cancelButton, addExistingEntity, modal } = this.refs as any;

		this.addEventListener(addNewCustomerButton, 'click', this.onAddNewCustomerClick);
		this.addEventListener(saveNewEntityButton, 'click', (e: any) => this.onAddEntity(e));
		this.addEventListener(cancelButton, 'click', this.onCancelButtonClick);

		if (this.refs.customerSearch != null) {
			this.addEventListener(modal, 'mouseup', (e: any) => this.onModalBodyClick(e));
		}

		addExistingEntity.forEach((entity: HTMLElement) => {
			this.addEventListener(entity, 'click', (e: any) => this.onAddExistingEntity(e));
		});
	}

	protected onModalBodyClick(e: any) {
		if (!(this.refs.customerSearch as any).contains(e.target)) {
			this.customerSearch.hideOptions();
		}
	}

	protected onAddNewCustomerClick = (e: any) => {
		e.preventDefault();
		this.showAddNewCustomer();
	};

	protected onAddEntity = (e: any) => {
		e.preventDefault();

		const addType = this.options.editMode === true ? AddType.EditExisting : AddType.AddNew;
		this.savePopup(e, addType);
	};

	protected onAddExistingEntity = (e: any) => {
		e.preventDefault();
		this.savePopup(e, AddType.AddExisting);
	};

	protected onCancelButtonClick = (e: any) => {
		e.preventDefault();
		this.cancelPopup();
	};

	protected showAddNewCustomer() {
		this.popupState = PopupState.AddNewEntityMapping;
		this.redraw();
	}

	async savePopup(e: any, addType: AddType) {
		let addCustomerResult: AddCustomerResult;
		switch (addType) {
			case AddType.EditExisting: {
				if (!this.addCustomerForm.checkValidity(this.data, true, null)) return;
				this.resultPromiseResolve(this.buildEditCustomerResult());
				break;
			}
			case AddType.AddExisting: {
				const customersService = AutoFactory.get(CustomersService);
				const customer = await customersService.getById(e.target.id);
				addCustomerResult = this.buildExistingCustomerResult(customer);
				this.resultPromiseResolve(addCustomerResult);
				break;
			}
			case AddType.AddNew: {
				if (!this.addCustomerForm.checkValidity(this.data, true, null)) return;
				this.resultPromiseResolve(this.buildNewCustomerResult());
				break;
			}
		}
	}

	protected cancelPopup() {
		const skipMainMenu = (this.applicationEntities.length === 0 && !this.isLender) || this.popupState === PopupState.EditEntity;
		if (skipMainMenu) {
			this.resultPromiseResolve();
		}

		// Go back to choose existing screen
		this.popupState = PopupState.MainMenu;
		this.redraw();
	}

	public buildExistingCustomerResult(customer: Customer) {
		const customerType = customer.type;
		const submissionKey = convertCustomerTypeToEntityType(customerType!);
		const dataFields = this.getDataFieldsByCustomerType(customerType!);

		return {
			customerType: customerType,
			[submissionKey]: buildExistingCustomerSubmissionObject(customer, dataFields)
		} as AddCustomerResult;
	}

	protected getDataFieldsByCustomerType(customerType: Customer.TypeEnum) {
		switch (customerType) {
			case Customer.TypeEnum.Business:
				return this.businessDataFields;
			case Customer.TypeEnum.Person:
				return this.personDataFields;
			case Customer.TypeEnum.Farm:
				return this.farmDataFields;
			default:
				return [];
		}
	}

	protected buildNewCustomerResult(): AddCustomerResult {
		const customerType: ApplicationTemplate.TypeEnum | undefined = this.data?.form?.data?.entityTypeRadio;

		switch (customerType) {
			case ApplicationTemplate.TypeEnum.Business:
				return this.getNewCustomerResult(CustomComponentType.businessInfo, Customer.TypeEnum.Business);
			case ApplicationTemplate.TypeEnum.Person:
				return this.getNewCustomerResult(CustomComponentType.personalInfo, Customer.TypeEnum.Person);
			case ApplicationTemplate.TypeEnum.Nonprofit:
				return this.getNewCustomerResult(CustomComponentType.nonprofitInfo, Customer.TypeEnum.NonProfit);
			case ApplicationTemplate.TypeEnum.Farm:
				return this.getNewCustomerResult(CustomComponentType.farmInfo, Customer.TypeEnum.Farm);
			default:
				throw new Error('Unsuported customer type');
		}
	}

	public buildEditCustomerResult(): AddCustomerResult {
		const result =
			this.getEditCustomerResult(CustomComponentType.businessInfo, Customer.TypeEnum.Business) ??
			this.getEditCustomerResult(CustomComponentType.personalInfo, Customer.TypeEnum.Person) ??
			this.getEditCustomerResult(CustomComponentType.nonprofitInfo, Customer.TypeEnum.NonProfit) ??
			this.getEditCustomerResult(CustomComponentType.farmInfo, Customer.TypeEnum.Farm);

		if (!result) throw new Error('Cannot find supported data object');

		return result;
	}

	protected getEditCustomerResult(componentType: CustomerComponentType, customerType: Customer.TypeEnum): AddCustomerResult | null {
		const result = this.getNewCustomerResult(componentType, customerType);
		const customerInfo = result[componentType];

		return customerInfo.data.id != null ? result : null;
	}

	protected getNewCustomerResult(componentType: CustomComponentType, customerType: Customer.TypeEnum): AddCustomerResult {
		const customerInfo = this.data?.form?.data[componentType];

		return { customerType, [componentType]: customerInfo };
	}

	protected addFromCustomerSearch(selectedCustomer: Customer) {
		this.resultPromiseResolve(this.buildExistingCustomerResult(selectedCustomer));
	}
}
