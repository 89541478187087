import Vue from 'vue';
import { DataFieldMetadata, DataObject, PresetDataFieldsByDataObject, TemplateDataField } from '@sageworks/dynamic-forms';
import {
	ApplicationTemplateMetadata,
	AutoFactory,
	LoanApplicationMetadata,
	OnlinePortalDataFieldsService,
	OnlinePortalLoanApplicationMetadataService,
	OnlinePortalLoanApplicationProposedLoanMappingsService,
	OnlinePortalLoanApplicationsService,
	ProposedProduct
} from '@sageworks/jpi';
import { defineModule } from 'direct-vuex';
import { fetchPresetDataFields } from '../utils/preset-data-field-helper';
import { moduleActionContext } from '.';
import { BorrowerNameDataFieldIds } from '@/models/BorrowerNameDataFieldIds';

// eslint-disable-next-line no-use-before-define
const actionContext = (context: any) => moduleActionContext(context, LoanApplicationMetadataModule);

const mergePresetFieldsInDataFieldMetadata = (presetFields: PresetDataFieldsByDataObject, dataFieldMetadata?: DataFieldMetadata) => {
	if (!dataFieldMetadata) return;

	Object.keys(presetFields).forEach(key => {
		// Needed since typescript is not liking the usage of an enum in place of the keys for this interface
		const fieldMetadata = dataFieldMetadata as any;

		if (!fieldMetadata[key]) {
			fieldMetadata[key] = [];
		}

		const presetFieldsForType = (presetFields[key as DataObject.TypeEnum] ?? []).map(x => x.id as number);
		fieldMetadata[key] = [...fieldMetadata[key], ...presetFieldsForType];
	});
};

export interface LoanApplicationMetadataModuleState {
	metadata: ApplicationTemplateMetadata[];
	loanApplicationId: number | null;
	borrowerNameDataFieldIds: BorrowerNameDataFieldIds;
	// To be deprecated soon
	presetDataFieldsByDataObject: PresetDataFieldsByDataObject;
	proposedProducts: ProposedProduct[];
}

const LoanApplicationMetadataModule = defineModule({
	namespaced: true,
	state: () => {
		return {
			metadata: [],
			loanApplicationId: null,
			borrowerNameDataFieldIds: {},
			// To be deprecated soon
			presetDataFieldsByDataObject: {},
			proposedProducts: []
		} as LoanApplicationMetadataModuleState;
	},
	mutations: {
		SET_METADATA(state, { metadata }: { metadata: ApplicationTemplateMetadata[] }) {
			state.metadata = metadata;
		},
		SET_PRESET_DATA_FIELDS_BY_DATA_OBJECT(state, presetDataFieldsByDataObject: PresetDataFieldsByDataObject) {
			state.presetDataFieldsByDataObject = presetDataFieldsByDataObject;
		},
		SET_LOAN_APPLICATION_ID(state, loanApplicationId: number) {
			state.loanApplicationId = loanApplicationId;
		},
		ADD_METADATA(state, metadata: ApplicationTemplateMetadata) {
			Vue.set(state.metadata, state.metadata.length, metadata);
		},
		DELETE_METADATA(state, { loanIndex }: { loanIndex: number }) {
			state.metadata = state.metadata.filter((_, index) => index !== loanIndex);
		},
		SET_BORROWER_NAME_DATA_FIELD_IDS(state, borrowerNameDataFieldIds: BorrowerNameDataFieldIds) {
			state.borrowerNameDataFieldIds = borrowerNameDataFieldIds;
		},
		SET_PROPOSED_PRODUCTS(state, proposedProducts: ProposedProduct[]) {
			state.proposedProducts = proposedProducts;
		}
	},
	actions: {
		async loadMetadata(
			context,
			{ loanApplicationId, ignoreFormioProperties = false }: { loanApplicationId: number; ignoreFormioProperties?: boolean }
		): Promise<LoanApplicationMetadata> {
			const { state, commit, dispatch } = actionContext(context);

			const service = AutoFactory.get(OnlinePortalLoanApplicationMetadataService);
			const metadata = await service.getMetadata(loanApplicationId, ignoreFormioProperties);

			if (Object.keys(state.presetDataFieldsByDataObject).length === 0) {
				const presetDataFields = await fetchPresetDataFields();
				commit.SET_PRESET_DATA_FIELDS_BY_DATA_OBJECT(presetDataFields);
			}

			// Populate data field metadata with required (preset) data fields
			const { presetDataFieldsByDataObject } = state;
			metadata.allApplicationTemplateMetadata?.forEach(templateMetadata => {
				mergePresetFieldsInDataFieldMetadata(presetDataFieldsByDataObject, templateMetadata.dataFieldIdsByType);
			});
			commit.SET_LOAN_APPLICATION_ID(loanApplicationId);
			commit.SET_METADATA({ metadata: metadata.allApplicationTemplateMetadata ?? [] });
			await dispatch.loadProposedProducts(loanApplicationId);

			return metadata;
		},
		async addLoanMetadata(context, { loanMappingId }: { loanMappingId: number }): Promise<any> {
			const { state, commit, dispatch } = actionContext(context);

			const service = AutoFactory.get(OnlinePortalLoanApplicationMetadataService);
			const metadata = await service.getTemplateMetadata(loanMappingId, true);

			const { presetDataFieldsByDataObject } = state;
			mergePresetFieldsInDataFieldMetadata(presetDataFieldsByDataObject, metadata.dataFieldIdsByType);
			commit.ADD_METADATA(metadata);
			await dispatch.loadProposedProducts(state.loanApplicationId!);
		},
		async removeLoan(context, { loanIndex }: { loanIndex: number }) {
			const { state, commit, dispatch } = actionContext(context);
			const metadata = state.metadata[loanIndex] as ApplicationTemplateMetadata;

			const loanMappingService = AutoFactory.get(OnlinePortalLoanApplicationProposedLoanMappingsService);
			await loanMappingService._delete(metadata.loanMappingId as number);
			commit.DELETE_METADATA({ loanIndex });
			await dispatch.loadProposedProducts(state.loanApplicationId!);
		},
		async retrieveBorrowerNameDataFields(context): Promise<void> {
			const { commit } = actionContext(context);

			const onlinePortaldataFieldsService = AutoFactory.get(OnlinePortalDataFieldsService);

			var borrowerNameFieldIds = [
				TemplateDataField.BusinessName,
				TemplateDataField.PersonalFirstName,
				TemplateDataField.PersonalLastName,
				TemplateDataField.FarmName
			];

			var result =
				(await onlinePortaldataFieldsService.getPaged(1, borrowerNameFieldIds.length, undefined, undefined, undefined, borrowerNameFieldIds)).items ??
				[];

			var borrowerNameDataFieldIds: BorrowerNameDataFieldIds = {
				businessNameId: result[0]?.id,
				personFirstNameId: result[1]?.id,
				personLastNameId: result[2]?.id,
				farmNameId: result[3]?.id
			};

			commit.SET_BORROWER_NAME_DATA_FIELD_IDS(borrowerNameDataFieldIds);
		},
		async loadProposedProducts(context, loanApplicationId: number): Promise<void> {
			const { state, commit } = actionContext(context);

			if (state.metadata.length > 0) {
				const proposedProductsFromMetadata = state.metadata.map(m => m.proposedProduct) as ProposedProduct[];
				commit.SET_PROPOSED_PRODUCTS(proposedProductsFromMetadata);
			} else {
				const loanApplicationService = AutoFactory.get(OnlinePortalLoanApplicationsService);
				const proposedProducts = await loanApplicationService.getProposedProductsByLoanApplicationId(loanApplicationId);
				commit.SET_PROPOSED_PRODUCTS(proposedProducts);
			}
		}
	},
	getters: {
		metadata({ metadata }) {
			return metadata.length > 0 ? metadata : undefined;
		},
		proposedProducts: state => {
			return state.proposedProducts;
		},
		firstMetadata({ metadata }) {
			return metadata.length > 0 ? metadata[0] : undefined;
		},
		firstApplicationTemplate(_, getters) {
			const firstMetadata = getters.firstMetadata as ApplicationTemplateMetadata | undefined;

			return firstMetadata?.applicationTemplate;
		}
	}
});

export default LoanApplicationMetadataModule;
