import { AutoFactory, OnlinePortalSBALoanInfoService, ProposedLoanSBAInfo } from '@sageworks/jpi';

export class SbaInfoApiHelper {
	public static async getSbaInfo(loanId: number) {
		const service = AutoFactory.get(OnlinePortalSBALoanInfoService);

		return await service.getOrForge(loanId);
	}

	public static async updateSbaInfo(sbaInfo: ProposedLoanSBAInfo) {
		if (!sbaInfo.id || sbaInfo.id < 0) {
			throw new Error('No valid id was passed with the SBA data');
		}

		const service = AutoFactory.get(OnlinePortalSBALoanInfoService);

		return await service.update(sbaInfo.id, sbaInfo);
	}
}
