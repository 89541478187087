import FormComponent from 'formiojs/components/form/Form';
import { BaseRoleRepeaterWidget } from '../base/base-role-repeater-widget/base-role-repeater-widget';
import { ContextDataProperty, LoanRoleType, TemplateDataField } from '../../../enums';
import { configureLinkBehavior, configureLinkTemplate } from '../base/edit-link-helper';
import { isInPreviewMode } from '../../../utils/formio-template-helpers/builder-template-helpers';
import { RepeaterWidgetUtils } from '../../../utils';
import { AutoFactory, DataField, OnlinePortalDataFieldsService } from '@sageworks/jpi';
import { ComponentDataLoader, ComponentDataLoadStatus } from '../../../utils/component-data-loader';
import { getContextDataValue } from '../../../utils/context-data-helper/context-data-helper';
import { PopupWrapper } from '../popup';
import { AddCustomerResult } from '../add-customer/add-customer-result';
import { showLoadingPopup } from '../../../utils/modals/loading-modal-helper';

export class RelatedRoleRepeaterWidget extends BaseRoleRepeaterWidget {
	static schema(...extend: any) {
		return BaseRoleRepeaterWidget.schema(
			{
				form: '',
				formPath: '',
				allowRepeat: true
			},
			...extend
		);
	}

	static get builderInfo() {
		return {
			group: '',
			weight: 10,
			schema: RelatedRoleRepeaterWidget.schema()
		};
	}

	get submitMetadata() {
		return {
			parentLoanRoleId: this.parentLoanRoleId
		};
	}

	protected get isJpiAuthenticated() {
		return getContextDataValue(this, ContextDataProperty.IsJpiAuthenticated);
	}

	protected dataLoader!: ComponentDataLoader;

	init() {
		this.component.modal = false;

		super.init();

		if (!this.dataLoader) {
			this.dataLoader = new ComponentDataLoader();
			this.initDataLoader();
		}
	}

	rowDescription(row: any, _rowIndex: number): string {
		try {
			if (this.dataLoader.status !== ComponentDataLoadStatus.Loaded) {
				return 'Loading...';
			}

			return this.rowDescriptionGetEntityDescription(row);
		} catch (error) {
			return 'Unable to load preview';
		}
	}

	render(children: any, topLevel?: boolean): any {
		if (this.builderMode) {
			return configureLinkTemplate(this, { showEditButton: false });
		} else {
			return super.render(children, topLevel);
		}
	}

	async attach(element: HTMLElement) {
		await super.attach(element);

		if (this.builderMode) {
			configureLinkBehavior(this, element);
			return;
		} else if (isInPreviewMode(this)) {
			return;
		}

		this.loadRefs(element, {
			[`${this.addRowRef}`]: 'single'
		});

		const addRowRef = this.refs[`${this.addRowRef}`];
		if (!this.parentLoanRoleId && addRowRef != null) {
			this.disableAddNewButton(addRowRef as any);
		}

		this.attachEventHandlers(element);
		this.dataLoader.loadData();
		this.component.components = this.componentComponents;
	}

	async showAddCustomerPopup(rowIndex: number, roleType: LoanRoleType, editMode = false): Promise<void> {
		const createPopupPromise = this.createAddNewCustomerPopup(rowIndex, roleType, editMode);
		showLoadingPopup(createPopupPromise);
		const addCustomerPopup = await createPopupPromise;

		let popupWrapper = new PopupWrapper({}, {}, {}, addCustomerPopup);

		popupWrapper.showPopup();
		const selectedCustomer = await popupWrapper.actionCompleted;

		if (selectedCustomer == null) {
			// No customer was selected, let Formio handle the cancel logic to reset the row to the proper state
			this.cancelRow(rowIndex);
			return;
		}
		await this.saveOrEditRow(rowIndex, selectedCustomer);
	}

	public async saveOrEditRow(rowIndex: number, addCustomerResult: AddCustomerResult) {
		const formComponent: FormComponent = this.editRows[rowIndex].components[0];
		await formComponent.subFormReady;

		formComponent.setValue(
			{
				...formComponent.subForm.getValue(),
				data: this.buildSaveData(addCustomerResult)
			},
			{
				fromSubmission: true
			}
		);

		this.editRows[rowIndex].data = formComponent.data;
		this.saveRow(rowIndex);
	}

	protected buildSaveData(addCustomerResult: AddCustomerResult) {
		return RepeaterWidgetUtils.createRowData(addCustomerResult, this.parentLoanRoleId);
	}

	attachEventHandlers(element: HTMLElement) {
		this.loadRefs(element, {});
	}

	addRow() {
		this.component.modal = true;
		var newRow = undefined;

		try {
			newRow = super.addRow();
		} finally {
			this.component.modal = false;
		}

		return newRow;
	}

	addRowModal(rowIndex: number): any {
		var editRow = this.editRows[rowIndex];

		if (editRow.state === 'new') {
			this.showAddCustomerPopup(rowIndex, this.roleType);
		} else {
			return super.addRowModal(rowIndex);
		}
	}

	protected initDataLoader() {
		this.dataLoader.addDataFunc(this.getAllowedDataFields.bind(this));

		this.dataLoader.isSuccessfulPromise.then(this.redraw.bind(this));
	}

	private async getAllowedDataFields(): Promise<void> {
		if (this.builderMode || !this.isJpiAuthenticated || (this.allowedDataFields && this.allowedDataFields.length > 0)) {
			return;
		}

		const dataFieldsService = AutoFactory.get(OnlinePortalDataFieldsService);
		const allAllowedTemplateIds = this.allowedPersonalTemplateDataFields
			.concat(this.allowedFarmTemplateDataFields)
			.concat(this.allowedBusinessTemplateDataFields);

		var allowedDataFields =
			(await dataFieldsService.getPaged(1, allAllowedTemplateIds.length, undefined, undefined, false, allAllowedTemplateIds)).items ?? [];

		if (allowedDataFields.some(x => x == null)) {
			throw Error('Unable to retrieve all DataFields');
		}
		this.allowedDataFields = allowedDataFields as DataField[];
	}

	private get allowedPersonalTemplateDataFields(): TemplateDataField[] {
		return [TemplateDataField.PersonalFirstName, TemplateDataField.PersonalLastName, TemplateDataField.PersonalEmail];
	}

	private get allowedBusinessTemplateDataFields(): TemplateDataField[] {
		return [TemplateDataField.BusinessName, TemplateDataField.BusinessState, TemplateDataField.BusinessIndustryCode];
	}
	private get allowedFarmTemplateDataFields(): TemplateDataField[] {
		return [TemplateDataField.FarmName, TemplateDataField.FarmState, TemplateDataField.FarmIndustryCode];
	}

	private disableAddNewButton(addNewRef: HTMLButtonElement) {
		addNewRef.disabled = true;
		this.addClass(addNewRef, 'disabled');
	}

	conditionallyVisible(data: any): boolean {
		if (this.components == null || this.containsData()) {
			return true;
		}
		return super.conditionallyVisible(data);
	}

	private containsData() {
		if (this.data[this.type]?.length > 0) {
			return true;
		}
	}
}
