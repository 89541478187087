import { StoreType } from '../../../store';
import { LoanApplication } from '@sageworks/jpi';
import { ApplicationValidation } from '@/utils';

export const updateMultiLoanApplicationStatus = async (store: StoreType, isProfileComplete: boolean) => {
	const loanApplication = store.state.LoanApplicationData.loanApplication as LoanApplication;
	const applicationStatus = loanApplication.applicationStatus;
	const submittedDate = loanApplication.submittedDate;
	const validationStatus = await getValidationStatus(store, isProfileComplete);

	let statusToUpdate: LoanApplication.ApplicationStatusEnum | undefined;

	if (!validationStatus.formDataValid) {
		statusToUpdate = LoanApplication.ApplicationStatusEnum.New;
	} else if (
		submittedDate != null &&
		validationStatus.formDataValid &&
		validationStatus.documentRequestsValid &&
		checkTermsAndConditions(store.getters.User.isLender, validationStatus.termsAndConditions)
	) {
		statusToUpdate = LoanApplication.ApplicationStatusEnum.ApplicationComplete;
	} else {
		statusToUpdate = LoanApplication.ApplicationStatusEnum.ProfileComplete;
	}

	await updateStatus(store, applicationStatus, statusToUpdate);
};

async function getValidationStatus(store: StoreType, isProfileComplete: boolean): Promise<ApplicationValidation.ApplicationSubmitValidationResults> {
	const loanApplication = store.state.LoanApplicationData.loanApplication as LoanApplication;
	const loanAppId = loanApplication.id;

	if (loanAppId == null) {
		throw new Error('Application id cannot be null');
	}
	const applicationValidator = new ApplicationValidation.MultiLoanApplicationValidator(store, loanAppId);

	const validationStatus: ApplicationValidation.ApplicationSubmitValidationResults = {};
	validationStatus.termsAndConditions = await applicationValidator.validateTermsAndConditions();
	validationStatus.documentRequestsValid = await applicationValidator.validateDocumentRequests();
	validationStatus.canBeSubmitted = await applicationValidator.validateApplicationSubmitStatus();
	validationStatus.formDataValid = isProfileComplete;

	return validationStatus;
}

function checkTermsAndConditions(isLender: boolean, termsAndConditionsComplete?: boolean) {
	return termsAndConditionsComplete || isLender;
}

async function updateStatus(
	store: StoreType,
	currentApplicationStatus?: LoanApplication.ApplicationStatusEnum,
	statusToUpdate?: LoanApplication.ApplicationStatusEnum
) {
	if (!statusToUpdate || statusToUpdate === currentApplicationStatus) {
		return Promise.resolve();
	}

	store.dispatch.LoanApplicationData.updateApplicationStatus({
		applicationStatus: statusToUpdate
	});
}
