import BootstrapTemplate from 'formiojs/templates/bootstrap';
import builder from './builder';
import builderComponent from './builderComponent';
import builderEditForm from './builderEditForm';
import builderSidebarGroup from './builderSidebarGroup';
import builderWizard from './builderWizard';
import copyForm from './copyForm';
import editgrid from './editgrid';
import input from './input';
import widgets from './widget';
import select from './select';
import wizard from './wizard';
import wizardHeader from './wizardHeader';
import wizardNav from './wizardNav';
import { form as landingPage, row as landingPageRow } from './landingPage';
import { getIconClass } from './icon-class';
import tab from './tab';
import panel from './panel';
import radio from './radio';
import creditReport from './creditReport';
import dialogCreditReportAuthorize from './dialogCreditReportAuthorize';
import creditReportButtons from './creditReportButtons';
import noPreview from './noPreview';
import dialogCreditReportPullCredit from './dialogCreditReportPullCredit';
import dialogCreditReportPullCreditComplete from './dialogCreditReportPullCreditComplete';
import authorizedSigner from './authorizedSigner';
import creditReportCredentials from './creditReportCredentials';
import creditReportPullCreditPopup from './dialogCreditReportPullCreditPopup';
import dialogAddCustomField from './dialogAddCustomField';
import styledDialog from './styledDialog';
import addCustomerPopup from './addCustomerPopup';
import borrowerAccessPopup from './borrowerAccessPopup';
import addCustomerDataFields from './addCustomerDataFields';
import customerSearch from './customerSearch';
import customerSearchOptions from './customerSearchOptions';
import addNewPrimaryRole from './addNewPrimaryRole';
import primaryRoleAddRowPopup from './primaryRoleAddRowPopup';
import loadingPopup from './loading-popup';
import nestedWizard from './nestedWizard';
import nestedWizardHeader from './nestedWizardHeader';
import nestedWizardNav from './nestedWizardNav';
import collateralAddRowPopup from './collateralAddRowPopup';
import vueDataList from './vueDataList';
import columns from './columns';

interface AbrigoBootstrapTemplate extends BootstrapTemplate {
	copyForm: object;
	widgets: object;
	landingPage: object;
	landingPageRow: object;
	creditReport: object;
	noPreview: object;
	dialogCreditReportAuthorize: object;
	creditReportButtons: object;
	dialogCreditReportPullCredit: object;
	dialogCreditReportPullCreditComplete: object;
	authorizedSigner: object;
	creditReportCredentials: object;
	creditReportPullCreditPopup: object;
	dialogAddCustomField: object;
	styledDialog: object;
	addCustomerPopup: object;
	addCustomerDataFields: object;
	customerSearch: object;
	customerSearchOptions: object;
	addNewPrimaryRole: object;
	primaryRoleAddRowPopup: object;
	loadingPopup: object;
	borrowerAccessPopup: object;
	nestedWizard: object;
	nestedWizardHeader: object;
	nestedWizardNav: object;
	collateralAddRowPopup: object;
	vueDataList: object;
	columns: object;
}

const AbrigoBootstrapInstance: AbrigoBootstrapTemplate = {} as AbrigoBootstrapTemplate;

Object.assign(AbrigoBootstrapInstance, BootstrapTemplate);
AbrigoBootstrapInstance.builder = builder;
AbrigoBootstrapInstance.builderComponent = builderComponent;
AbrigoBootstrapInstance.builderEditForm = builderEditForm;
AbrigoBootstrapInstance.builderSidebarGroup = builderSidebarGroup;
AbrigoBootstrapInstance.builderWizard = builderWizard;
AbrigoBootstrapInstance.copyForm = copyForm;
AbrigoBootstrapInstance.editgrid = editgrid;
AbrigoBootstrapInstance.input = input;
AbrigoBootstrapInstance.panel = panel;
AbrigoBootstrapInstance.radio = radio;
AbrigoBootstrapInstance.widgets = widgets;
AbrigoBootstrapInstance.select = select;
AbrigoBootstrapInstance.tab = tab;
AbrigoBootstrapInstance.wizard = wizard;
AbrigoBootstrapInstance.wizardHeader = wizardHeader;
AbrigoBootstrapInstance.wizardNav = wizardNav;
AbrigoBootstrapInstance.landingPage = landingPage;
AbrigoBootstrapInstance.landingPageRow = landingPageRow;
AbrigoBootstrapInstance.iconClass = getIconClass;
AbrigoBootstrapInstance.creditReport = creditReport;
AbrigoBootstrapInstance.dialogCreditReportAuthorize = dialogCreditReportAuthorize;
AbrigoBootstrapInstance.noPreview = noPreview;
AbrigoBootstrapInstance.creditReportButtons = creditReportButtons;
AbrigoBootstrapInstance.dialogCreditReportPullCredit = dialogCreditReportPullCredit;
AbrigoBootstrapInstance.dialogCreditReportPullCreditComplete = dialogCreditReportPullCreditComplete;
AbrigoBootstrapInstance.authorizedSigner = authorizedSigner;
AbrigoBootstrapInstance.creditReportCredentials = creditReportCredentials;
AbrigoBootstrapInstance.creditReportPullCreditPopup = creditReportPullCreditPopup;
AbrigoBootstrapInstance.dialogAddCustomField = dialogAddCustomField;
AbrigoBootstrapInstance.styledDialog = styledDialog;
AbrigoBootstrapInstance.addCustomerPopup = addCustomerPopup;
AbrigoBootstrapInstance.addCustomerDataFields = addCustomerDataFields;
AbrigoBootstrapInstance.customerSearchOptions = customerSearchOptions;
AbrigoBootstrapInstance.customerSearch = customerSearch;
AbrigoBootstrapInstance.addNewPrimaryRole = addNewPrimaryRole;
AbrigoBootstrapInstance.primaryRoleAddRowPopup = primaryRoleAddRowPopup;
AbrigoBootstrapInstance.loadingPopup = loadingPopup;
AbrigoBootstrapInstance.borrowerAccessPopup = borrowerAccessPopup;
AbrigoBootstrapInstance.nestedWizard = nestedWizard;
AbrigoBootstrapInstance.nestedWizardHeader = nestedWizardHeader;
AbrigoBootstrapInstance.nestedWizardNav = nestedWizardNav;
AbrigoBootstrapInstance.collateralAddRowPopup = collateralAddRowPopup;
AbrigoBootstrapInstance.vueDataList = vueDataList;
AbrigoBootstrapInstance.columns = columns;

export default AbrigoBootstrapInstance;
