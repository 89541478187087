import editForm from './credit-report.form';
import { CustomComponentType, ContextDataProperty } from '../../../enums';
import Component from 'formiojs/components/_classes/component/Component';
import { isInPreviewMode } from '../../../utils/formio-template-helpers/builder-template-helpers';
import { configureLinkTemplate, configureLinkBehavior } from '../base/edit-link-helper';
import { getContextDataValue } from '../../../utils/context-data-helper/context-data-helper';
import { getLoanApplicationType } from '../../../utils/app-type-locator';
import {
	DynamicApplicationMessage,
	LoanApplicationCreditAuthorization,
	DataField,
	DynamicApplicationThirdPartySetting,
	AutoFactory,
	OnlinePortalCreditReportService,
	ApplicationTemplate
} from '@sageworks/jpi';
import CreditReportAuthorizationInput from './credit-report-authorization-input/credit-report-authorization-input';
import {
	PullCreditDataFieldIds,
	getCreditPullAuthorization,
	getCreditReportAuthorizationMessage,
	getEntityIdFromCorrectParent,
	waiveCreditPull,
	unwaiveCreditPull,
	getUserDisplayName,
	AuthorizationDisplayObjectFactory
} from './helpers';
import { getThirdPartySettings } from '../../../utils/third-party-settings-helper';
import CreditReportPullCreditPopup from './credit-report-pull-credit-popup/credit-report-pull-credit-popup';
import { PopupWrapper } from '../popup';
import { checkConditionalityAcrossForms, shouldFieldUseOriginalConditionality } from '../simple-inputs/extended-field-helper';
import { FormioConditionalityUtils } from '../../../utils/formio-conditionality-utils';

enum DataLoadStatus {
	NotLoaded,
	Loading,
	Loaded
}

export default class CreditReportWidget extends Component {
	private thirdPartySettings: DynamicApplicationThirdPartySetting | null = null;
	private authorizationMessage: string | null = null;
	private dataLoadStatus = DataLoadStatus.NotLoaded;
	private waivedByUsername: string | null = null;
	private onDataInit: Promise<any> | null = null;
	private hasLenderPullAccess: boolean = false;

	private get loanApplicationType() {
		return getContextDataValue(this, ContextDataProperty.LoanApplicationType);
	}

	private get loanApplicationId() {
		return getContextDataValue(this, ContextDataProperty.LoanApplicationId);
	}

	private get userInfo() {
		return getContextDataValue(this, ContextDataProperty.CurrentUserInfo);
	}

	private get isJpiAuthenticated() {
		return getContextDataValue(this, ContextDataProperty.IsJpiAuthenticated);
	}

	private get customerId(): number {
		return getEntityIdFromCorrectParent(this);
	}

	private get isLender() {
		return getContextDataValue(this, ContextDataProperty.IsLender);
	}

	// @ts-ignore
	get emptyValue(): any {
		return {};
	}

	// @ts-ignore
	get key() {
		return this.component.type;
	}

	get readOnly(): boolean {
		return this.options.readOnly;
	}

	get authorizationMetadata(): LoanApplicationCreditAuthorization | null {
		return this.dataValue[CreditReportWidget.authorizationKey];
	}

	set authorizationMetadata(value: LoanApplicationCreditAuthorization | null) {
		const originalValue = this.dataValue[CreditReportWidget.authorizationKey];
		this.dataValue[CreditReportWidget.authorizationKey] = value;

		if (originalValue !== this.dataValue[CreditReportWidget.authorizationKey]) {
			this.onChange({ modified: true });
		}
	}

	static schema(...extend: any) {
		return Component.schema(
			{
				label: 'Credit Report',
				type: CustomComponentType.creditReport,
				key: CustomComponentType.creditReport,
				dataFields: []
			},
			...extend
		);
	}

	static editForm = editForm;

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	static createBuilderInfo(dataFields: DataField[], appType: ApplicationTemplate.TypeEnum) {
		const filteredDataFields = dataFields.filter(field => PullCreditDataFieldIds.has(field.templateDataFieldId || -1));

		return {
			title: 'Credit Report',
			group: '',
			weight: 10,
			schema: CreditReportWidget.schema({ dataFields: filteredDataFields })
		};
	}

	static get builderInfo() {
		return {
			title: 'Credit Report',
			group: '',
			weight: 10,
			schema: CreditReportWidget.schema()
		};
	}

	static get authorizationKey() {
		return 'credit-report-authorization';
	}

	init() {
		this.authorizationMessage = this.authorizationMessage ?? 'Loading...';
		super.init();
	}

	private loadInitData() {
		if (this.dataLoadStatus !== DataLoadStatus.NotLoaded) return;

		this.dataLoadStatus = DataLoadStatus.Loading;
		this.onDataInit = Promise.all([
			this.setupAuthorizationMetadata(),
			this.setupAuthorizationMessage(),
			this.setupThirdPartySettings(),
			this.setupLenderPullAccess()
		]).then(() => {
			this.dataLoadStatus = DataLoadStatus.Loaded;
			this.redraw();
		});
	}

	public render(): any {
		if (this.builderMode) {
			return configureLinkTemplate(this, { showEditButton: false });
		} else if (isInPreviewMode(this)) {
			return super.renderTemplate('noPreview', { name: 'Credit Report Widget' });
		}

		return super.render(
			super.renderTemplate('creditReport', {
				message: this.authorizationMessage,
				buttons: super.renderTemplate('creditReportButtons', this.getRenderContext()),
				classes: this.className
			})
		);
	}

	private getRenderContext() {
		const authorizeDetails = AuthorizationDisplayObjectFactory.createAuthorizationDisplayObject(this.authorizationMetadata);
		const pullCreditDetails = AuthorizationDisplayObjectFactory.createCreditPullDisplayObject(this.authorizationMetadata);
		const waiveDetails = AuthorizationDisplayObjectFactory.createWaivedDisplayObject(this.authorizationMetadata, this.waivedByUsername);

		const canBorrowerPullCredit = !this.isLender && this.thirdPartySettings?.enableCreditAuthWhenUserAuthorizes;
		const canLenderPullCredit = this.isLender && this.hasLenderPullAccess;
		const canAuthorize = !authorizeDetails && !waiveDetails;
		const showButtons = !this.options.readOnly;

		const showWaiveButton = this.isLender && canAuthorize && !pullCreditDetails;
		const showUnWaiveButton = this.isLender && waiveDetails && !pullCreditDetails;
		const showAuthorizeAndPullButton = !this.isLender && canBorrowerPullCredit && !pullCreditDetails && canAuthorize;
		const showAuthorizeButton = !this.isLender && canAuthorize && !showAuthorizeAndPullButton;
		const showDisabledAuthorizeButton = this.isLender && canAuthorize;
		const showPullCreditButton = (canLenderPullCredit || (canBorrowerPullCredit && authorizeDetails)) && !pullCreditDetails && !waiveDetails;

		return {
			authorizeDetails,
			pullCreditDetails,
			waiveDetails,
			showWaiveButton: showButtons && showWaiveButton,
			showUnWaiveButton: showButtons && showUnWaiveButton,
			showAuthorizeButton: showButtons && showAuthorizeButton,
			showDisabledAuthorizeButton: showButtons && showDisabledAuthorizeButton,
			showAuthorizeAndPullButton: showButtons && showAuthorizeAndPullButton,
			showPullCreditButton: showButtons && showPullCreditButton
		};
	}

	private async setupAuthorizationMetadata() {
		if (this.isJpiAuthenticated) {
            let authorization = (await getCreditPullAuthorization(this.customerId, this.loanApplicationId)) ?? {};
			// We manually set the dataValue instead of using the setter to avoid onchange event, since this is just
			// initializing the data
			this.dataValue[CreditReportWidget.authorizationKey] = authorization;

			if (this.authorizationMetadata?.waivedByUserId) {
				this.waivedByUsername = await getUserDisplayName(this.authorizationMetadata.waivedByUserId);
			}
		}
	}

	private async setupAuthorizationMessage() {
		if (this.isJpiAuthenticated) {
			this.authorizationMessage = await this.getAuthorizationMessage();
		} else {
			this.authorizationMessage = 'Credit Report Terms load here';
		}
	}

	private async setupThirdPartySettings() {
		if (this.isJpiAuthenticated) {
			this.thirdPartySettings = await getThirdPartySettings(this.loanApplicationType);
		}
	}

	private async setupLenderPullAccess() {
		if (this.isJpiAuthenticated && this.isLender) {
			const service = AutoFactory.get(OnlinePortalCreditReportService);
			this.hasLenderPullAccess = (await service.userHasAccessToPullCredit()).userHasAccessToPullCredit ?? false;
		}
	}

	public async attach(element: HTMLElement) {
		await super.attach(element);

		if (this.builderMode) {
			configureLinkBehavior(this, element);
			return;
		} else if (isInPreviewMode(this)) {
			return;
		}

		this.attachEventHandlers(element);

		this.loadInitData();
	}

	private cleanAndAddEvent(ref: HTMLElement, eventType: string, actionToTake: (e: Event) => void) {
		this.removeEventListener(ref, eventType);
		this.addEventListener(ref, eventType, actionToTake);
	}

	// eslint-disable-next-line max-lines-per-function
	private attachEventHandlers(element: HTMLElement) {
		this.loadRefs(element, {
			authorizeAuditMessage: 'single',
			pullAuditMessage: 'single',
			waiveAuditMessage: 'single',
			authorizeCreditButton: 'single',
			authorizeAndPullCreditButton: 'single',
			waiveCreditButton: 'single',
			unwaiveCreditButton: 'single',
			pullCreditButton: 'single'
		});

		const { authorizeCreditButton, authorizeAndPullCreditButton, waiveCreditButton, unwaiveCreditButton, pullCreditButton } = this.refs as any;

		if (authorizeCreditButton) {
			this.cleanAndAddEvent(authorizeCreditButton, 'click', this.onAuthorizeCreditClick);
		}

		if (authorizeAndPullCreditButton) {
			this.cleanAndAddEvent(authorizeAndPullCreditButton, 'click', this.onAuthorizeAndPullCreditClick);
		}

		if (waiveCreditButton) {
			this.cleanAndAddEvent(waiveCreditButton, 'click', this.onWaiveClick);
		}

		if (unwaiveCreditButton) {
			this.cleanAndAddEvent(unwaiveCreditButton, 'click', this.onUnWaiveClick);
		}

		if (pullCreditButton) {
			this.cleanAndAddEvent(pullCreditButton, 'click', this.onLenderPullCreditClick);
		}
	}

	private async showAuthorizeCreditDialog() {
		let creditAuthorizationInputComponent = new CreditReportAuthorizationInput(
			CreditReportAuthorizationInput.schema({
				authorizationMetadataId: this.authorizationMetadata?.id,
				authorizationMessage: this.authorizationMessage,
				customerId: this.customerId
			}),
			{
				...this.options,
				parent: this,
				skipInit: false
			},
			this.dataValue
		);

		let popupWrapper = new PopupWrapper({}, { parent: this }, {}, creditAuthorizationInputComponent);

		popupWrapper.showPopup();
		const result = await popupWrapper.actionCompleted;

		if (result) {
			this.authorizationMetadata = result;
		}

		this.redraw();
	}

	private async showPullCreditDialog() {
		let pullCreditInputComponent = new CreditReportPullCreditPopup(
			CreditReportPullCreditPopup.schema({
				customerId: this.customerId,
				dataFields: this.component.dataFields,
				authorizationMetadataId: this.authorizationMetadata?.id,
				defaultCredentialsId: this.thirdPartySettings?.externalIntegrationUniversalCreditReportOptionsID
			}),
			{
				...this.options,
				parent: this,
				skipInit: false
			},
			this.dataValue
		);

		let popupWrapper = new PopupWrapper({}, { parent: this }, {}, pullCreditInputComponent);

		popupWrapper.showPopup();
		const result = await popupWrapper.actionCompleted;

		if (result) {
			this.authorizationMetadata = result;
		}

		this.redraw();
	}

	private async getAuthorizationMessage() {
		let dynamicAppMessage = await getCreditReportAuthorizationMessage(
			getLoanApplicationType(this),
			DynamicApplicationMessage.MessageTypeEnum.CreditReportAuthorization
		);

		return dynamicAppMessage?.message || '';
	}

	/**
	 *  Arrow functions to preserve `this` property
	 */

	onAuthorizeCreditClick = async () => {
		await this.showAuthorizeCreditDialog();
	};

	onAuthorizeAndPullCreditClick = async () => {
		await this.showAuthorizeCreditDialog();
		await this.showPullCreditDialog();
	};

	onLenderPullCreditClick = async () => {
		await this.showPullCreditDialog();
	};

	onWaiveClick = async () => {
		let jpiAuthentication = this.isJpiAuthenticated;
		let userInfo = this.userInfo;

		if (!jpiAuthentication) {
			return;
		} else if (userInfo.proxyUserId == null) {
			return;
		}
		this.authorizationMetadata = await waiveCreditPull(
			this.authorizationMetadata?.id,
			this.loanApplicationId,
			this.customerId,
			userInfo.proxyUserId,
			'N/A'
		);
		this.waivedByUsername = await getUserDisplayName(this.authorizationMetadata.waivedByUserId);
		return this.redraw();
	};

	onUnWaiveClick = async () => {
		let jpiAuthentication = this.isJpiAuthenticated;
		let userInfo = this.userInfo;

		if (!jpiAuthentication) {
			return;
		} else if (userInfo.proxyUserId == null) {
			return;
		}
		this.authorizationMetadata = await unwaiveCreditPull(this.authorizationMetadata?.id ?? -1);
		this.waivedByUsername = null;
		return this.redraw();
	};

	conditionallyVisible(data: any): boolean {
		if (shouldFieldUseOriginalConditionality(this)) {
			return super.conditionallyVisible(data);
		}
		return checkConditionalityAcrossForms(this);
	}

	checkCondition(row: any, data: any) {
		return FormioConditionalityUtils.checkCondition(
			this.component,
			row || this.data,
			data || this.rootValue,
			this.root ? (this.root as any)._form : {},
			this
		);
	}

	/**
	 * Checks the validity of this component and sets the error message if it is invalid.
	 *
	 * @param data
	 * @param dirty
	 * @param row
	 * @return {boolean}
	 */
	checkComponentValidity(data: any, dirty: boolean, row: any, options = {} as any) {
		data = data || this.rootValue;
		row = row || this.data;
		const { async = false, silentCheck = false } = options;

		if (this.shouldSkipValidation(data, dirty, row)) {
			this.setCustomValidity('', dirty);
			return async ? Promise.resolve(true) : true;
		}

		if (!async) {
			return this.synchronousCheckValidity(dirty, silentCheck);
		} else {
			return this.asynchronousCheckValidity(dirty, silentCheck);
		}
	}

	private synchronousCheckValidity(dirty: boolean, silentCheck: boolean) {
		let check = [];
		if (
			this.authorizationMetadata?.authorizedDate == null &&
			this.authorizationMetadata?.pullDate == null &&
			this.authorizationMetadata?.waivedDate == null
		) {
			check.push({
				message: 'Authorization is required.',
				level: 'error'
			});
		}

		this.setComponentValidity(check, dirty, silentCheck);
		return check.length === 0;
	}

	private async asynchronousCheckValidity(dirty: boolean, silentCheck: boolean) {
		await this.onDataInit;
		if (
			this.authorizationMetadata?.authorizedDate == null &&
			this.authorizationMetadata?.pullDate == null &&
			this.authorizationMetadata?.waivedDate == null
		) {
			this.setComponentValidity(
				[
					{
						message: 'Authorization is required.',
						level: 'error'
					}
				],
				dirty,
				silentCheck
			);
			return false;
		}
		return true;
	}
}
