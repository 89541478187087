
import Vue, { PropType } from 'vue';
import { ApplicationSubmitValidationErrorMessage, ApplicationSubmitValidationErrors, ApplicationSubmitValidationResults } from '@/utils/application-validation';
import { ApplicationValidationStage } from '@/enums';
import ApplicationValidationSteps from './application-validation-steps.vue';
import ApplicationValidationErrorMessage from './application-validation-error-message.vue';
import ApplicationValidationSuccessMessage from './application-validation-success-message.vue';
import { UsableProducts } from '@sageworks/jpi';

export default Vue.extend({
	name: 'ApplicationValidationModal',
	components: {
		ApplicationValidationSteps,
		ApplicationValidationErrorMessage,
		ApplicationValidationSuccessMessage
	},
	props: {
		applicationId: {
			type: Number,
			default: undefined
		},
		isValidating: {
			type: Boolean as PropType<boolean>,
			default: false
		},
		isSubmitting: {
			type: Boolean as PropType<boolean>,
			default: false
		},
		isSendingESignEmails: {
			type: Boolean as PropType<boolean | undefined>,
			default: undefined
		},
		currentUserIsLender: {
			type: Boolean as PropType<boolean>,
			default: false
		},
		currentValidationStage: {
			type: Number as PropType<ApplicationValidationStage>,
			default: ApplicationValidationStage.NotValidating
		},
		validationResults: {
			type: Object as PropType<ApplicationSubmitValidationResults>,
			default: () => ({})
		},
		submissionSuccessful: {
			type: Boolean as PropType<boolean | undefined>,
			default: undefined
		},
		eSignEmailSendingSucceeded: {
			type: Boolean as PropType<boolean | undefined>,
			default: undefined
		},
		customerCanUseEsign: {
			type: Boolean as PropType<boolean>,
			default: false
		},
		errors: {
			type: Object as PropType<ApplicationSubmitValidationErrors | undefined>,
			default: undefined
		},
		isBypassingSignatures: {
			type: Boolean as PropType<boolean>,
			default: false
		}
	},
	data() {
		return {
			ApplicationValidationStage: ApplicationValidationStage
		};
	},
	computed: {
		hasLosLiteSubscription(): boolean | undefined {
			return this.directStore.state.UsableProducts.usableProducts?.has('abrigoCommunityLending' as UsableProducts.ProductsEnum);
		},
		applicationRequestLabel(): string {
			return this.hasLosLiteSubscription ? 'Request Form' : 'Application';
		},
		modalTitle(): string {
			return this.customerCanUseEsign && !this.isBypassingSignatures
				? `Validating ${this.applicationRequestLabel}`
				: `Submitting ${this.applicationRequestLabel}`;
		},
		isActionInProgress(): boolean {
			return this.isValidating || this.isSubmitting || !!this.isSendingESignEmails;
		},
		isActionSuccessful(): boolean {
			return this.errors?.messages?.length === 0 && (!!this.submissionSuccessful || !!this.eSignEmailSendingSucceeded);
		},
		successMessage(): string {
			if (this.customerCanUseEsign && !this.isBypassingSignatures) {
				return 'Signature requests have been sent successfully to all applicants!';
			}
			return `Congratulations, your ${this.applicationRequestLabel.toLowerCase()} was successfully submitted!`;
		},
		errorMessages(): ApplicationSubmitValidationErrorMessage[] {
			return this.errors?.messages ?? [];
		}
	}
});
