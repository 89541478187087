
import Vue, { PropType } from 'vue';
import AddNewPrimaryRole from '@/formio-vue/components/primary-role/add-new-primary-role.vue';
import { Customer } from '@sageworks/jpi/';
import { CustomComponentType } from '@sageworks/dynamic-forms';
import EntityList from '../entity-list/entity-list.vue';

export default Vue.extend({
	name: 'EntityRepeater',
	components: { AddNewPrimaryRole, EntityList },
	props: {
		entityTypes: {
			type: Array as PropType<Customer.TypeEnum[]>,
			default: () => []
		},
		componentType: {
			type: Object as PropType<CustomComponentType>,
			default: () => {}
		},

		rowDetails: {
			type: Array as PropType<string[]>,
			default: () => [],
			required: true
		},
		maxRows: {
			type: Number,
			default: () => 0
		}
	}
});
