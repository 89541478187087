import { library, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
	faClipboardList,
	faFileSignature,
	faAngleRight,
	faStream,
	faBallotCheck,
	faBallot,
	faPlus,
	faTrash,
	faFileDownload,
	faFileUpload,
	faBars,
	faTimes,
	faArrowCircleLeft,
	faAngleLeft,
	faLock,
	faShieldCheck,
	faExclamationCircle,
	faTrashAlt,
	faCircle,
	faCheckCircle,
	faPencil,
	faFingerprint,
	faPercent,
	faDollarSign
} from '@fortawesome/pro-regular-svg-icons';

import {
	faEdit as faEditSolid,
	faTrash as faTrashSolid
} from '@fortawesome/pro-solid-svg-icons';

export default function initializeFortAwesome() {
	const icons = [
		faClipboardList,
		faFileSignature,
		faAngleRight,
		faStream,
		faBallotCheck,
		faBallot,
		faPlus,
		faTrash,
		faFileDownload,
		faFileUpload,
		faBars,
		faTimes,
		faArrowCircleLeft,
		faAngleLeft,
		faCircle,
		faCheckCircle,
		faLock,
		faShieldCheck,
		faExclamationCircle,
		faTrashAlt,
		faEditSolid,
		faTrashSolid,
		faPencil,
		faFingerprint,
		faPercent,
		faDollarSign
	] as IconDefinition[];

	library.add(...icons);
}
