export enum TemplateDataField {
	// Customer Properties - Personal Info
	PersonalFirstName = 45,
	PersonalLastName = 47,
	PersonalEmail = 48,
	PersonalSSN = 53,
	PersonalMiddleName = 543,
	PersonalSuffix = 2208,
	PersonalStreetAddress = 57,
	PersonalCity = 61,
	PersonalState = 58,
	PersonalZipCode = 60,
	PersonalCountry = 1911,
	CitizenshipStatus = 2184,

	// Customer Properties - Business Info
	BusinessName = 2,
	BusinessPhone = 9,
	BusinessState = 18,
	BusinessIndustryCode = 10,
	BusinessSubEntityType = 2339,
	FranchiseDataFranchiseName = 2352,
	MailingAddressDifferent = 3117,
	DidBusinessExceed5Million = 3520,
	GrossAnnualRevenue = 3525,
	BorrowerAddressType = 3528,

	// Customer Properties - Farm Info
	FarmName = 1021,
	FarmState = 1047,
	FarmIndustryCode = 1037,

	// Customer Properties - Collateral Info
	CollateralDescription = 442,
	CollateralType = 443,
	CollateralPledgedBy = 444,

	// Vehicle Properties - Collateral Info
	VehicleState = 3229,
	VehicleMake = 460,
	VehicleModel = 461,
	VehicleYear = 463,
	VehicleBodyStyle = 464,

	// Customer Properties - Nonprofit Info
	NonprofitName = 2,
	NonprofitPhone = 9,
	NonprofitState = 18,
	NonprofitSectorCode = 10,

	// Proposed Loan
	ProposedLoanLoanAmount = 127,
	ProductCode = 152,
	LoanTypeCode = 153,
	PurposeCode = 154,
	CallCode = 150,
	CollateralCode = 151,

	// BeneficialOwner
	BeneficialOwnerPercent = 3143,
	BeneficialOwnerDateUpdated = 3144,
	BeneficialOwnerRoleType = 3145,
	BeneficialOwnerCompanyCustomerId = 3167,
	BeneficialOwnerOwnerCustomerId = 3168,

	// FinancialSubaccounts
	SubaccountName = 3106,
	SubaccountValue = 3107,
	SubaccountCustomer = 3111,
	SubaccountFinancialDataId = 3110,
	SubaccountFinancialStatement = 3109,
	SubaccountParentField = 3108,

	// Personal Financial Statements
	PfsCustomer = 1215,
	PfsPeriodEndDate = 1319,

	// Proposed Products
	ProposedProductDropdown = 3102,

	// Real Estate Financials
	RealEstatePropertyId = 1506,
	RealEstatePropertyName = 1507,
	RealEstatePropertyType = 1508,
	RealEstatePropertyValue = 1529,
	RealEstatePropertyOwnership = 1553,
	RealEstateOwnerFinancialDataId = 3339,

	// Business Investments
	BusinessInvestmentOwnerCustomerId = 3335,
	BusinessInvestmentOwnerFinancialDataId = 3336,
	BusinessInvestmentInvestmentEntityId = 3337,
	BusinessInvestmentValue = 3338,

	// HMDA
	HmdaLoanPurpose = 2381
}
