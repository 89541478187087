
import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { mapGetters } from 'vuex';

export default Vue.extend({
	name: 'PlaidComponent',
	components: {
		FontAwesomeIcon
	},
	props: {
		componentId: {
			type: String,
			required: true
		},
		addNewBtnLabel: {
			type: String,
			default: 'Add Bank Account(s)'
		},
		customerId: {
			type: Number
		},
		customMessage: {
			type: String,
			default: 'By providing your banking data, our lenders are able to make faster decisions, and offer you best possible terms.'
		}
	},
	computed: {
		...mapGetters('Plaid', ['selectDataAssets'])
	},
	watch: {
		customerId: {
			handler() {
				this.loadInitialData();
			},
		}
	},
	methods: {
		loadInitialData: async function () {
			await Promise.all([
				this.directStore.dispatch.Plaid.loadDataAssets({ customerId: this.customerId }),
			]);
		}
	},
});
