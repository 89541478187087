
import Vue from 'vue';
import ConfirmReasonModal from '../confirm-reason-modal/confirm-reason-modal.vue';

export default Vue.extend({
	name: 'BorrowerNotUploadingReasonModal',
	components: { ConfirmReasonModal },
	props: {
		isLender: Boolean,
		documentRequestId: Number,
		documentName: String,
		notUploadedReason: String,
		documentNamesId: Number
	},
	data() {
		return {
			adobeTemplateStatus: ''
		};
	},
	computed: {
		modalId(): string {
			return `borrowerNotUploadingReasonModal-${this.documentRequestId}`;
		},
		reasonId(): string {
			return `viewReason-${this.documentRequestId}`;
		},
		truncateReason(): string {
			let truncatedReason = this.notUploadedReason;
			if (truncatedReason.length > 40) {
				truncatedReason = truncatedReason.substring(0, 39) + '...';
			}
			return truncatedReason;
		},
		reasonForNotUploadingText(): string {
			this.loadAdobeDocumentStatus(this.documentRequestId);
			if (this.adobeTemplateStatus === 'NOT_A_VALID_ADOBE_TEMPLATE' || this.adobeTemplateStatus === '') {
				return 'If you are unable to upload this document at this time, please provide an explanation below:';
			}
			return 'If you are unable to sign this document at this time, please provide an explanation below:';
		},
		reasonErrorMessageText(): string {
			this.loadAdobeDocumentStatus(this.documentRequestId);
			if (this.adobeTemplateStatus === 'NOT_A_VALID_ADOBE_TEMPLATE' || this.adobeTemplateStatus === '') {
				return 'Please enter an explanation for why you are unable to upload this document at this time';
			}
			return 'Please enter an explanation for why you are unable to sign this document at this time';
		}
	},
	async beforeMount() {
		this.loadAdobeDocumentStatus(this.documentRequestId);
	},
	methods: {
		saveReason({ reason }: { reason: string }) {
			this.directStore.dispatch.ApplicationDocumentRequest.patchApplicationDocumentRequest({
				id: this.documentRequestId,
				documentRequestProperties: { notUploadedReason: reason }
			});
		},
		async loadAdobeDocumentStatus(documentRequestId: number): Promise<void> {
			if (this.adobeTemplateStatus !== '') {
				return;
			}
			try {
				this.adobeTemplateStatus = await this.directStore.dispatch.AdobeESignModule.fetchTemplateStatus({
					documentRequestId: documentRequestId,
					documentNamesId: this.documentNamesId
				});
			} catch (error) {
				// do nothing. if we can't get the status we don't want adobe info to show.
			}
		}
	}
});
