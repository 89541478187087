
import { FormioTemplate } from '@/enums';
import { ApplicationExportMetadata } from '@/models';
import { RenderUtils } from '@/utils';
import { customComponentToTemplateMapping, formioComponentToTemplateMapping } from '@/utils/render-utils';
import { CustomComponentType, FormioComponentType } from '@sageworks/dynamic-forms';
import Vue, { PropType } from 'vue';

export default Vue.extend({
	name: 'BaseTemplateMixin',
	components: {},
	props: {
		exportMetadata: {
			type: Object as PropType<ApplicationExportMetadata>
		}
	},
	computed: {
		isVisible(): boolean {
			return this.conditionallyVisible && this.showLenderOnlyField && !this.hideInPdf && !this.hidden;
		},
		conditionallyVisible(): boolean {
			return this.configurationLookup?.isVisible ?? true;
		},
		showLenderOnlyField(): boolean {
			return this.exportMetadata.ComponentTree.lenderOnly ? this.isLenderPdf : true;
		},
		dataLookupPath() {
			return [this.exportMetadata.ComponentPath, this.exportMetadata.ComponentTree.id].filter(x => x !== '').join('.');
		},
		configurationLookup() {
			return this.directStore.state.PDFPreviewModule.renderData[this.exportMetadata.LoanIndex].configurationLookup![this.dataLookupPath];
		},
		isLenderPdf() {
			return this.exportMetadata.LenderOnlyMode && this.directStore.getters.User.isLender;
		},
		hideInPdf() {
			return this.exportMetadata.ComponentTree.hideInPdf;
		},
		hidden() {
			return this.exportMetadata.ComponentTree.hidden;
		}
	},
	beforeCreate() {
		this.$options.components.FormioColumns = require('./columns.vue').default;
		this.$options.components.FormioFieldset = require('./fieldset.vue').default;
		this.$options.components.FormioInput = require('./input.vue').default;
		this.$options.components.FormioTable = require('./table.vue').default;
		this.$options.components.FormioForm = require('./formio-form.vue').default;
		this.$options.components.FormioHtml = require('./html.vue').default;
		this.$options.components.FormioContent = require('./content.vue').default;
		this.$options.components.FormioTabs = require('./tabs.vue').default;
		this.$options.components.FormioPanel = require('./panel.vue').default;
		this.$options.components.FormioWell = require('./well.vue').default;
		this.$options.components.FormioRoleRepeater = require('./role-repeater.vue').default;
		this.$options.components.FormioCreditReport = require('./credit-report.vue').default;
		this.$options.components.FormioRelatedRoleRepeater = require('./related-role-repeater.vue').default;
		this.$options.components.FormioEntityInfoForm = require('./entity-info-form.vue').default;
		this.$options.components.FormioBusinessInvestments = require('./business-investments.vue').default;
	},
	methods: {
		getComponent(componentType: CustomComponentType | FormioComponentType): FormioTemplate {
			let component;
			if (formioComponentToTemplateMapping.has(componentType)) {
				component = formioComponentToTemplateMapping.get(componentType) as FormioTemplate;
			} else if (customComponentToTemplateMapping.has(componentType)) {
				component = customComponentToTemplateMapping.get(componentType) as FormioTemplate;
			} else {
				component = FormioTemplate.FormioInput;
			}

			return component;
		},
		getChildComponents(currentComponent: any): any {
			return RenderUtils.getChildComponents(currentComponent);
		},
		getComponentPath(): string {
			return [this.exportMetadata.ComponentPath, this.exportMetadata.ComponentTree.id].filter(x => x !== '' && x != null).join('.');
		},
		buildExportMetadata(childComponent: any) {
			return {
				ComponentTree: childComponent,
				ComponentPath: this.getComponentPath(),
				LoanIndex: this.exportMetadata.LoanIndex,
				LenderOnlyMode: this.exportMetadata.LenderOnlyMode
			} as ApplicationExportMetadata;
		}
	}
});
