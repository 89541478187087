import ModifiedFormComponent from '../../formio-components/form/modified-form-component';
import { DataField, LoanApplication } from '@sageworks/jpi';
import PersonalInfoWidget from '../../personal-info/personal-info';
import BusinessInfoWidget from '../../business-info/business-info';
import RadioComponent from 'formiojs/components/radio/Radio';
import { StaticFormUtils } from '../../../../utils/static-form-utils';
import { CustomComponentType, TemplateDataField } from '../../../../enums';
import { FormioValidationSchema } from '../../../../formio-interfaces';
import { customConditional, getRadioSchema } from './add-new-customer-helper';
import FarmInfoWidget from '../../farm-info/farm-info';

export default class AddNewCustomer extends ModifiedFormComponent {
	protected static defaultRadioValue: string = 'person';

	static schema(editMode: boolean, dataFields: DataField[], applicationType: LoanApplication.TypeEnum, formJson?: any, ...extend: any) {
		return ModifiedFormComponent.schema(
			{
				type: CustomComponentType.addNewCustomer,
				key: 'form',
				formJson: formJson ?? this.getFormComponents(editMode, dataFields, applicationType)
			},
			...extend
		);
	}

	attach(element: any): any {
		if (this.options.readOnly) {
			return Promise.resolve();
		}

		return super.attach(element);
	}

	public restoreValue() {
		if (this.hasSetValue) {
			this.setValue(this.dataValue, {
				fromSubmission: true
			});
		} else {
			this.setDefaultValue();
		}
	}

	public getSubOptions() {
		const options = super.getSubOptions();
		options.customConditional = customConditional;
		return options;
	}

	protected static getFormComponents(editMode: boolean, dataFields: DataField[], applicationType: LoanApplication.TypeEnum): any {
		return {
			components: [
				RadioComponent.schema(getRadioSchema(editMode, applicationType, this.defaultRadioValue)),
				FarmInfoWidget.schema(this.getChildSchema(CustomComponentType.farmInfo, dataFields, editMode)),
				PersonalInfoWidget.schema(this.getChildSchema(CustomComponentType.personalInfo, dataFields, editMode)),
				BusinessInfoWidget.schema(this.getChildSchema(CustomComponentType.businessInfo, dataFields, editMode))
			]
		};
	}

	protected static getChildSchema(componentType: CustomComponentType, dataFields: DataField[], editMode: boolean) {
		let templateDataFields: TemplateDataField[];

		switch (componentType) {
			case CustomComponentType.personalInfo:
				templateDataFields = this.getPersonDataFields();
				break;
			case CustomComponentType.farmInfo:
				templateDataFields = this.getFarmDataFields();
				break;
			default:
				templateDataFields = this.getBusinessDataFields();
				break;
		}

		const dataFieldsForComponent = dataFields.filter(field => {
			if (field.templateDataFieldId == null) {
				return false;
			}

			return templateDataFields.includes(field.templateDataFieldId);
		});

		return {
			components: [...Object.values(this.convertDataFieldsToSchema(dataFieldsForComponent))],
			customConditional: this.getCustomCondition(),
			editMode: editMode,
			preserveConditional: true
		};
	}

	protected static convertDataFieldsToSchema(dataFields: DataField[]) {
		const schema = StaticFormUtils.dataFieldListToSchemaMap(dataFields);
		const requiredFields = this.getRequiredFields();
		const dataFieldLabelOverrides = this.getDataFieldLabelOverrides();

		const validationSchema: FormioValidationSchema = {
			customPrivate: false,
			required: true
		};

		Object.keys(schema).forEach((templateIdString: string) => {
			const templateId: number = parseInt(templateIdString);
			if (requiredFields.includes(templateId)) {
				schema[templateId].validate = validationSchema;
			}
			if (dataFieldLabelOverrides.has(templateId)) {
				schema[templateId].label = dataFieldLabelOverrides.get(templateId);
			}
		});

		return schema;
	}

	protected static getCustomCondition(): string {
		return `
			show = instance.options.customConditional(instance);			
		`;
	}

	protected static getPersonDataFields(): TemplateDataField[] {
		return [TemplateDataField.PersonalFirstName, TemplateDataField.PersonalLastName, TemplateDataField.PersonalEmail];
	}

	protected static getBusinessDataFields(): TemplateDataField[] {
		return [TemplateDataField.BusinessName, TemplateDataField.BusinessState, TemplateDataField.BusinessIndustryCode];
	}

	protected static getFarmDataFields(): TemplateDataField[] {
		return [TemplateDataField.FarmName, TemplateDataField.FarmState, TemplateDataField.FarmIndustryCode];
	}

	protected static getRequiredFields(): TemplateDataField[] {
		return [TemplateDataField.PersonalFirstName, TemplateDataField.PersonalLastName, ...this.getBusinessDataFields(), ...this.getFarmDataFields()];
	}

	protected static getDataFieldLabelOverrides(): Map<TemplateDataField, string> {
		return new Map<TemplateDataField, string>();
	}
}
