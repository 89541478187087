
import { Component, Mixins } from 'vue-property-decorator';
import { RouteMixin } from '@/mixins';
import { IdentityVerificationHeader } from '@/components';
import { IdentityVerificationRequest } from '@sageworks/jpi';
import { getFailureOptions } from '@sageworks/dynamic-forms/src/utils/toastr-utils';
import { formatSocialSecurityNumber } from '@/utils/FormattingUtils';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';

const inputDateFormat = 'm/D/YYYY';
const ouptutDateFormat = 'YYYY-mm-DD';

const inputFields = {
	birthDate: 'birthDate',
	firstName: 'firstName',
	lastName: 'lastName',
	ssn: 'ssn',
	address: 'address',
	city: 'city',
	state: 'state',
	zip: 'zip'
};

@Component({
	components: { IdentityVerificationHeader },
	validations: {
		firstName: {
			noErrorOverrides(_, { modelErrorOverrides }) {
				return !modelErrorOverrides.has(inputFields.firstName);
			},
			required
		},
		lastName: {
			noErrorOverrides(_, { modelErrorOverrides }) {
				return !modelErrorOverrides.has(inputFields.lastName);
			},
			required
		},
		ssn: {
			noErrorOverrides(_, { modelErrorOverrides }) {
				return !modelErrorOverrides.has(inputFields.ssn);
			},
			required
		},
		birthDate: {
			required,
			noErrorOverrides(_, { modelErrorOverrides }) {
				return !modelErrorOverrides.has(inputFields.birthDate);
			},
			validFormat(val) {
				return moment(val, inputDateFormat, true).isValid();
			}
		},
		address: {
			noErrorOverrides(_, { modelErrorOverrides }) {
				return !modelErrorOverrides.has(inputFields.address);
			},
			required
		},
		city: {
			noErrorOverrides(_, { modelErrorOverrides }) {
				return !modelErrorOverrides.has(inputFields.city);
			},
			required
		},
		state: {
			noErrorOverrides(_, { modelErrorOverrides }) {
				return !modelErrorOverrides.has(inputFields.state);
			},
			required
		},
		zip: {
			noErrorOverrides(_, { modelErrorOverrides }) {
				return !modelErrorOverrides.has(inputFields.zip);
			},
			required
		}
	}
})
export default class VerifyIdentity extends Mixins(RouteMixin) {
	newAccount: boolean = this.directStore.state.Authentication.newAccount;
	submitting: boolean = false;
	showValidation = false;
	modelErrorOverrides = new Map<string, string>();
	inputFields = inputFields;
	unknownErrors: string[] = [];

	async created() {
		const user = this.directStore.state.User.user;
		if (user != null) {
			this.identityInformation.firstName = user.firstName ?? '';
			this.identityInformation.lastName = user.lastName ?? '';
		}
	}

	get firstName() {
		return this.identityInformation.firstName;
	}
	set firstName(value: string) {
		this.identityInformation.firstName = value;
	}
	get firstNameValidationState() {
		if (!this.showValidation || !this.$v.firstName.$invalid) {
			return null;
		} else {
			return false;
		}
	}

	get lastName() {
		return this.identityInformation.lastName;
	}
	set lastName(value: string) {
		this.identityInformation.lastName = value;
	}
	get lastNameValidationState() {
		if (!this.showValidation || !this.$v.lastName.$invalid) {
			return null;
		} else {
			return false;
		}
	}

	get ssn() {
		return this.identityInformation.ssn;
	}
	set ssn(value: string) {
		this.identityInformation.ssn = value;
	}
	get ssnValidationState() {
		if (!this.showValidation || !this.$v.ssn.$invalid) {
			return null;
		} else {
			return false;
		}
	}

	get birthDate() {
		const translatedDate = moment(this.identityInformation.birthDate, ouptutDateFormat, true);
		if (translatedDate.isValid()) {
			return translatedDate.format(inputDateFormat);
		}
		return this.identityInformation.birthDate;
	}
	set birthDate(value: string) {
		const translatedDate = moment(value, inputDateFormat, true);
		if (translatedDate.isValid()) {
			this.identityInformation.birthDate = translatedDate.format(ouptutDateFormat);
		} else {
			this.identityInformation.birthDate = value;
		}
	}
	get birthDateValidationState() {
		if (!this.showValidation || !this.$v.birthDate.$invalid) {
			return null;
		} else {
			return false;
		}
	}

	get address() {
		return this.identityInformation.address;
	}
	set address(value: string) {
		this.identityInformation.address = value;
	}
	get addressValidationState() {
		if (!this.showValidation || !this.$v.address.$invalid) {
			return null;
		} else {
			return false;
		}
	}

	get city() {
		return this.identityInformation.city;
	}
	set city(value: string) {
		this.identityInformation.city = value;
	}
	get cityValidationState() {
		if (!this.showValidation || !this.$v.city.$invalid) {
			return null;
		} else {
			return false;
		}
	}

	get state() {
		return this.identityInformation.state;
	}
	set state(value: string) {
		this.identityInformation.state = value;
	}
	get stateValidationState() {
		if (!this.showValidation || !this.$v.state.$invalid) {
			return null;
		} else {
			return false;
		}
	}

	get zip() {
		return this.identityInformation.zip;
	}
	set zip(value: string) {
		this.identityInformation.zip = value;
	}
	get zipValidationState() {
		if (!this.showValidation || !this.$v.zip.$invalid) {
			return null;
		} else {
			return false;
		}
	}
	setModelErrorOverrides(errors: any[]) {
		this.modelErrorOverrides = new Map<string, string>();
		this.unknownErrors = [];
		for (const error of errors) {
			if (error.propertyName) {
				this.modelErrorOverrides.set(error.propertyName, error.message);
			} else if (error?.message?.indexOf('years') >= 0) {
				this.modelErrorOverrides.set(inputFields.birthDate, error.message);
			} else if (error?.message) {
				this.unknownErrors.push(error?.message);
			}
		}
	}

	getValidationError(field: string) {
		const overrideForField = this.modelErrorOverrides.get(field);
		if (overrideForField) {
			return overrideForField;
		}
		switch (field) {
			case inputFields.firstName:
				return 'Please enter a first name';
			case inputFields.lastName:
				return 'Please enter a last name';
			case inputFields.birthDate:
				return 'Please enter a valid date of birth (MM/DD/YYYY)';
			case inputFields.ssn:
				return 'Please enter a SSN';
			case inputFields.city:
				return 'Please enter a city';
			case inputFields.state:
				return 'Please enter a state';
			case inputFields.zip:
				return 'Please enter a zip code';
			case inputFields.address:
				return 'Please enter an address';
			default:
				return '';
		}
	}

	async submit() {
		this.setModelErrorOverrides([]);
		if (this.$v.$invalid) {
			this.showValidation = true;
			return;
		}
		if (this.submitting) {
			return;
		}
		this.submitting = true;
		this.ssn = this.ssn.replace(/-/gi, '');
		try {
			await this.directStore.dispatch.Login.verifyIdentity();
			await this.$router.push(this.OnlinePortalRoutes.VerifyKnowledgeBasedAnswers.path);
		} catch (e) {
			let modelErrors = Array.isArray(e?.response?.modelErrors) ? e.response?.modelErrors : [];
			this.setModelErrorOverrides(modelErrors);
			this.showValidation = true;
			const errorsList = this.unknownErrors.join(', ');
			this.$root.$bvToast.toast(
				`We could not verify your identity. Please make sure all fields are filled out correctly. ${errorsList}`,
				getFailureOptions()
			);
		} finally {
			this.submitting = false;
		}
	}

	get identityInformation(): IdentityVerificationRequest {
		return this.directStore.state.Login.identityVerificationRequest;
	}
	formatSSN(input: string) {
		return formatSocialSecurityNumber(input);
	}
}
